/* =============================================================================
// Local Variables, Mixins, and Placeholders
  ========================================================================== */
%widget-title {
  margin: 0;
  padding: 0;
  font-size: $p21-widget-title-size;
  font-weight: $font-weight-bold;
  line-height: $p21-widget-title-line-height;
}

/* =============================================================================
   Widget Base: Default
   ========================================================================== */
.widget-box {
  background: none repeat scroll 0 0 $p21-widget-background-color;
  border: $p21-widget-border-size $p21-widget-border-style $p21-widget-border-color;
  margin-bottom: $form-grid-gutter-width * 2;
  box-shadow: $p21-widget-box-shadow;
}

.widget-title {
  display: flex;
  align-items: center;
  padding: ($p21-widget-title-padding-y * $p21-widget-title-line-height) $p21-widget-title-padding-x $p21-widget-title-padding-y;
  color: $gray-600;
  text-shadow: 0 1px 0 $white;
  border-bottom: $p21-widget-border-size $p21-widget-border-style $p21-widget-border-color;
  box-shadow: $p21-widget-header-box-shadow;
  background-image: $p21-widget-header-gradient;

  & .icon {
    @extend %widget-title;
    position: relative;
    color: inherit;
    padding-right: $p21-widget-title-padding-x / 2;
    margin-right: $p21-widget-title-padding-x / 2;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: ($p21-widget-title-padding-y * $p21-widget-title-line-height) * -1;
      right: $border-width * -4;
      width: $border-width * 2;
      height: calc(100% + (#{$p21-widget-title-padding-y} * #{$p21-widget-title-line-height}) + #{$p21-widget-title-padding-y});
      background: $border-color;
      border-left: $border-width solid $white;
    }
  }

  h5 {
    @extend %widget-title;
    color: inherit;
    padding-left: calc((#{$p21-widget-title-padding-x} / 2) - (#{$border-width} * 2));
    margin-left: $p21-widget-title-padding-x / 2;
  }
}

.widget-content {
  position: relative;
  padding: $p21-widget-spacer-y $p21-widget-spacer-x;
}

.widget-title-link {
  @extend %widget-title;
  margin-left: auto;
}


/* =============================================================================
// Widget Base: Links default
//
// Issue Management > Data Reports (One or multi column)
  ========================================================================== */
// Default state
.widget-link {
  display: flex;
  padding: $p21-widget-spacer-y $p21-widget-spacer-x;
  margin: 0 ($p21-widget-spacer-x * -1);
  color: $body-color;
  line-height: 1.3;
  border-bottom: $p21-widget-border-size $p21-widget-border-style $p21-widget-border-color;
  transition: $transition-base;

  &:first-of-type {
    margin-top: ($p21-widget-spacer-y * -1);
  }

  &:last-of-type {
    margin-bottom: ($p21-widget-spacer-y * -1);
    border-bottom: none;
  }

  &__title {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }

  &__description {
    color: $text-muted;
  }

  &__meta {
    color: $text-muted;
    margin-left: auto;
  }
}

// Hover state
.widget-link:hover {
  background: $p21-table-row-bg-hover;
  cursor: pointer;
  color: darken($body-color, 40%);

  & .widget-link__title {
    text-decoration: $p21-widget-link-hover-decoration;
  }

  & .widget-link__description {
    color: darken($text-muted, 40%);
  }

  & .widget-link__meta {
    color: darken($text-muted, 40%);
    text-decoration: $p21-widget-link-hover-decoration;
  }
}

// Disabled state
.widget-link:disabled, .widget-link.disabled {
  pointer-events: none;
  text-decoration: none;
  opacity: $btn-disabled-opacity;
}


/* =============================================================================
   Widget Overrides
   ========================================================================== */
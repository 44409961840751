/* =============================================================================
// DataTables Base: Wrapper/Container for the toolbar, table, and footer
//
// .dataTables_wrapper DTS data-table-container
  ========================================================================== */
// Not sure what this is but copying it over from theme.css as a precaution
.ui-widget-overlay {
  position: fixed;
}

.dataTables_length {
  position: absolute;
  margin: ($spacer / 2) ($spacer / 4) 0;
  color: $gray-800;
  right: 2px;
  top: -2px;

  & div {
    vertical-align: middle;
  }
}

// Data Table Headers
.DTS {
  & thead th,
  & .server-side tbody td {
    white-space: nowrap;
  }
}

.dataTables_wrapper {
  &.table-buttons tbody .btn {
    visibility: hidden;
  }

  &.table-buttons tbody tr:hover .btn {
    visibility: visible;
  }

  // Not sure what this is but copying it over from theme.css as a precaution
  & .ui-widget-header {
    height: 2.25rem;
    margin-top: $border-width * -1;
    font-weight: $font-weight-normal;
    background-color: $gray-100;
    border-radius: $border-radius $border-radius 0 0;
    border-top: $border-width solid #D5D5D5;
    border-right: medium none;
    border-bottom: $border-width solid $border-color;
    box-shadow: 0 1px 0 $white inset;

    @media (max-width: 979px) {
      height: 4.25rem;
    }
  }

  & .ui-toolbar {
    padding: $spacer / 4;
    margin: 0;
    border-bottom: 0 !important;
  }
}


/* =============================================================================
// DataTables Base: Loader
  ========================================================================== */
div.DTS div.DTS_Loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-top: -20px;
  margin-left: -100px;
  z-index: 1;
  border: $border-width solid $border-color;
  padding: 20px 0;
  text-align: center;
  background-color: rgba($white, 0.5);
}


/* =============================================================================
// DataTables Base: Toolbar
  ========================================================================== */
// Toolbar container
.dataTables_toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.625rem;
  padding: 0.625rem;
  background-color: $gray-400;
  border-radius: $border-radius;

  & .btn {
    margin: 0;
  }

  .dt-buttons {
    @extend .btn-group;
  }

  // to override import of css in react datatables 
  .ui-button-text { 
    background: transparent;
  }
}

// Bulk actions and other actions to the left of the searchbar
.dataTables_actions,
.dataTables_toolbar .btn-wrapper {
  display: flex;

  & .btn {
    font-weight: $font-weight-bold;
    margin-right: $p21-table-spacer-x / 2;
  }

  & .btn:last-child {
    margin-right: $p21-table-spacer-x;
  }
}

// Search bar
.dataTables_filter {
  margin: 0; 
  font-size: $font-size-sm;

  & label {
    display: inline;
    margin-bottom: 0;
  }

  .dataTables_wrapper & input,
  // Search bar that still in the datatables filter but used in handsontables
  .handsontable-search {
    min-height: 1.5rem;
    margin: 0;
    padding-left: 1.3rem;
    font-size: $font-size-base;
    background-image: url('/assets/images/icons/fa/search-solid.svg');
    background-repeat: no-repeat;
    background-size: 1em 1em;
    background-position-x: 0.25rem;
    background-position-y: center;
    background-color: $input-bg;
    border: $border-width solid $p21-filters-border-color;
    border-radius: $border-radius;
    transition: $input-transition;
    @include form-control-focus($ignore-warning: true);

    &:hover {
      border-color: $input-focus-border-color;
    }

    // Placeholder
    &::placeholder {
      color: $input-placeholder-color;
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }
  }
}

// Filters
.dataTables_extraFilters {
  flex: 1 1 20%;
  display: flex;
  flex-wrap: wrap;
  margin-left: $p21-table-spacer-x;
  margin-bottom: $p21-filters-margin-y * -1;

  & .btn:not(:disabled):not(.disabled):not([disabled]) {
    border-color: $p21-filters-border-color;
    font-weight: $font-weight-bold;
    margin-right: $p21-table-spacer-x / 2;
    margin-bottom: $p21-filters-margin-y;

    & .badge {
      min-width: 1rem;
      margin-left: 0.3rem;
      transform: translateY(.05rem); // true badges to account for line height differences
      font-size: 95%;
      text-shadow: none;
    }
  }

  & .btn:not(:disabled):not(.disabled):not([disabled]):hover {
    border-color: $p21-filters-border-color;
  }
}

// Similar to .dataTables_extraFilters in that in comes after the search bar
.dataTables_toolbar .view-options {
  margin-left: $p21-table-spacer-x;

  & .btn:not(:disabled):not(.disabled):not([disabled]),
  & .btn:not(:disabled):not(.disabled):not([disabled]):hover {
    border-color: $p21-filters-border-color;
    font-weight: $font-weight-bold;
  }

  & label:not(.custom-control):not(.custom-select__label) {
    display: inline;
    font-size: 0.75rem;
  }

  & .multiselect-container label:not(.custom-control) {
    display: block;
  }
}

// Sort by button dropdown
.dataTables_extraTools {
  & .btn:not(:disabled):not(.disabled):not([disabled]) {
    border-color: $p21-filters-border-color;
    font-weight: $font-weight-bold;
  }
}

.dataTables_tools,
.dataTables_extraTools,
.DTTT_container {
  margin-left: auto;
}


/* =============================================================================
// DataTables Base: Table Scroll
  ========================================================================== */
// Div container for the table body
.dataTables_scroll {
  background: url(/assets/images/loading-background.png) repeat 0 0 $white;

  & table {
    max-width: none;
  }
}

// Div container for the table header row
.dataTables_scrollHead, 
.dt-print-view .dataTable thead {
  background: url(/assets/images/table-hdr.jpg) repeat-x $gray-600;
  border-bottom: $border-width solid $border-color;
}

.dataTables_scrollBody {
  z-index: 2;
}


/* =============================================================================
// DataTables Base: Table
  ========================================================================== */
// Apply this styling before the data tables is loaded on the screen
.data-table th {
  opacity: 0;
}

table.dataTable {
  & thead {
    // Table header cell
    & th {
      padding: $table-cell-padding-sm 0 $table-cell-padding-sm $table-cell-padding;
      text-align: left;
      color: $white;
      font-weight: $font-weight-bold;
      text-shadow: $gray-800 1px 1px 1px;
      line-height: 1.3;
      cursor: pointer;
      white-space: nowrap;
      opacity: 1; // needed after the table is loaded
    }

    & .number-cell,
    & .decimal-cell,
    & .percent-cell,
    & .fraction-cell,
    & .properFraction-cell {
      text-align: right;
    }

    & .bool-cell {
      text-align: center;
    }

    // Sort icon
    & .DataTables_sort_wrapper {
      position: relative;
      padding-right: $p21-table-spacer-x * 2;

      & .ui-icon {
        position: absolute;
        top: 50%;
        right: inherit;
        height: 16px;
        width: 16px;
        margin-top: -8px;
        background-image: url('/assets/images/ui-icons_cccccc_256x240.png');
        pointer-events: all;
      }

      & .ui-icon-triangle-1-n {
        background-position: 0 -16px;
      }

      & .ui-icon-triangle-1-s {
        background-position: -64px -16px;
      }

      & .ui-icon-carat-2-n-s,             // non-react spelling
      & .ui-icon-caret-2-n-s {            // react spelling
        background-position: -128px 0;
      }

      & .select-all {
        display: inline-block;
        vertical-align: middle;
      }

      & .select-all.custom-control{
        pointer-events: none;
      }
    }
  }

  // Table row
  tbody {
    & tr:hover {
      background-color: $p21-table-row-bg-hover;
    }

    // Row styling
    // Add object wizard selected row
    & tr.selected,
    & tr.row-selected {
      
      color: $p21-table-row-bg-selected-color;
      background-color: $p21-table-row-bg-selected;

      // add top/bottom border to selected items
      td {
        position: relative;

        &::after,
        &::before {
          position: absolute;
          inset: 0;
          background-color: $dropdown-link-active-border;
          content: '';
          height: 1px;
        }

        &::after {
          inset-block-start: 100%;
        }
      }
    }

    & tr.row_error {
      background-color: lighten($danger, 38%);
    }

    // Cell styling
    & td {
      padding: $table-cell-padding-sm $table-cell-padding-sm $table-cell-padding-sm $table-cell-padding;
      border-bottom: $border-width solid $gray-200;

      & i {
        opacity: 0.9;
        filter: alpha(opacity=90);
      }
    }

    & .dataTables_empty {
      text-align: left;
    }

    & .number-cell,
    & .decimal-cell,
    & .percent-cell,
    & .fraction-cell,
    & .properFraction-cell {
      text-align: right;
    }

    & .properFraction-cell,
    & .number-cell {
      font-variant-numeric: tabular-nums;
    }

    & .stringArray-cell p {
      margin-bottom: 0;
    }

    & .bool-cell {
      text-align: center;
      font-size: 0;
    }

    & .bool-cell i {
      font-size: $font-size-base;
    }

    & .button-cell > .btn-link {
      padding-left: 0;
    }

    // Sub-header row for tables in light gray. Found in IM > Issues
    & .dtrg-group td,
    & .group td,
    & td.group {
      padding: $table-cell-padding-sm $table-cell-padding;
      color: $p21-table-sub-head-color;
      font-weight: $font-weight-bold;
      background-image: linear-gradient(to bottom, $gray-200, $gray-300);

      & .total {
        margin-left: $p21-table-spacer-x;
        font-weight: $font-weight-normal;
      }
    }
  }
}

// vertical align cells to the top. used for User => SSH Keys where the length of the key unbalances the columns. this is a ridiculous string to override dataTables.jqueryui.css
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > .data-table--top > tbody > tr > td {
    vertical-align: top;
}

.data-table .data-table__ssh-key {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    overflow: hidden;

    // for long strings that are "jumping" between sizes when using tabs in User => SSH Keys...the prevents the resulting very narrow column lengths
    @include media-breakpoint-up(md) {
        width: 325px;
    }

    &.is-open {
        line-clamp: initial;
        -webkit-line-clamp: initial;
    }
}

.dataTables_body {
  & .dts_label {
    display: none;
    opacity: 0;
  }
}

/* =============================================================================
// DataTables Base: Footer
//
// Div container for the table footer
  ========================================================================== */
.dataTables_footer, .hot_footer {
  display: flex;
  align-items: center;
  padding: ($table-cell-padding / 2) $table-cell-padding; 
  font-size: 0.75rem;
  color: $gray-900;
  background-color: $gray-400;
  overflow: hidden;

  // Records found text
  & .dataTables_info, .hot_info {
    margin: 0;
    padding: 0;
    font-weight: $font-weight-bold;
  }

  // Prototype styling for DataTables Footer Selection Count
  & .selection_info {
    clear: none;
    margin-left: $spacer;
  }

  // Link for View Deactivated, View All, etc
  & .btn-wrapper,
  & .right-footer-contents {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}


/* =============================================================================
   DataTables Base: Print View
   ========================================================================== */
.dt-print-view {
  & .dataTables_toolbar .btn {
    margin-right: $spacer;
  }
}


/* =============================================================================
// DataTables Overrides: Table Header Auto Height
//
// This wraps the text in the table header to span multiple lines eg Admin > Users
   ========================================================================== */
.tableHeader--auto-height {
  & .dataTables_scrollHead {
    height: auto;
  }
   
  & .DTS thead th {
    white-space: normal;
  }
   
  & table.dataTable thead .DataTables_sort_wrapper .DataTables_sort_icon {
    right: 0.25rem;
  }
}


/* =============================================================================
// DataTables Overrides: Checkbox Column
//
// When the entire row is clickable, remove that interaction in this column
   ========================================================================== */
.checkbox-cell {
  pointer-events: none;

  & .custom-control-input, 
  & input {
    pointer-events: all;
    cursor: pointer;
  }

  & .custom-control-input:disabled, 
  & input:disabled {
    pointer-events: none;
    cursor: default;
  }

  & label.custom-control {
    margin-bottom: 0;
  }
}


/* =============================================================================
// DataTables Overrides: Link Column
   ========================================================================== */
.link-cell__info {
  display: block;

  & .secondary-info {
    display: block;
    font-size: 0.75rem;
  }
}

.link-cell__image {
  display: block;
  margin-right: $spacer / 4;
}


/* =============================================================================
// DataTables Overrides: Home Page Table
   ========================================================================== */
#your-projects td.datetime-cell {
  white-space: nowrap;
}

#your-data-packages .dropdown .dropdown-item.disabled {
  cursor: not-allowed;
}

// Table cell design for Progress and Score stat
.table-cell-stat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100px;
}

// Style the progress bar on the home page table
.table-cell-stat--progress {
  & .progress {
    width: 50px;
    height: $progress-height;
    margin: 0 $spacer 0 0;
    border-radius: $progress-border-radius;
    box-shadow: none;
    background: $progress-bg;
  }
  
  & .progress-bar {
    min-width: 1rem;
    border-radius: $progress-border-radius;
    box-shadow: none;
  }
} 

// Style the score cel on the home page table
.table-cell-stat--projected-score {
  max-width: 3rem;
}


/* =============================================================================
// DataTables Overrides: Cell Styling
   ========================================================================== */
// Success   
.cell-success,
.cell-created {
  background: $p21-cell-bg-success;

  &-key {
    color: $p21-cell-bg-success;
  }
}

// Warning
.cell-warning,
.cell-changed {
  background: $p21-cell-bg-warning;

  &-key {
    color: $p21-cell-bg-warning;
  }
}
 
.cell-warning > i[class~='fa-exclamation-triangle'],
.panel-title > i[class~='fa-exclamation-triangle'] {
  color: darken($warning, 7%);
  float: right;
}

// Danger  
.cell-danger, 
.cell-removed {
  background: $p21-cell-bg-danger;

  &-key {
    color: $p21-cell-bg-danger;
  }
}

.cell-danger > i[class~='fa-exclamation-circle'],
.panel-title > i[class~='fa-exclamation-circle'] {
  color: darken($danger, 7%);
  float: right;
}

// Info
.cell-info {
  background: $p21-cell-bg-info;

  &-key {
    color: $p21-cell-bg-info;
  }
}
  
.cell-info > i[class~='fa-info-circle'],
.panel-title > i[class~='fa-info-circle'] {
  color: darken($info, 7%);
  float: right;
}

// Critical
.cell-critical {
  background-color: lighten(theme-color("reject"), 38%);
}
  
.cell-critical > i[class~='fa-ban'],
.panel-title > i[class~='fa-ban'] {
  color: theme-color("reject");
  float: right;
}


/* =============================================================================
// DataTables Overrides: Change in data for table
   ========================================================================== */
.positive-change {
  color: $danger;
}
  
.negative-change {
  color: $success;
}

.change-tooltip {
  & li {
    text-align: initial;
  }
  
  & b {
    padding-right: 5.833em;
  }
}


/* =============================================================================
// DataTables Overrides:Report Suites
   ========================================================================== */
#report-suite-studies {
  & td.reports {
    white-space: nowrap;
  }
  
  & td a:hover {
    text-decoration: underline !important;
  }
}


/* =============================================================================
// DataTables Overrides: Empty
   ========================================================================== */
.dataTables_empty {

  // Add Objects fullscreen modal
  .miller-columns__column & {
    border: none;
    background: $p21-widget-background-color;
  } 

  // Notifcations sidebar modal
  .notification-table & {
    border: none;
    background: $p21-widget-background-color;
  }
}

/* =============================================================================
// DataTables Overrides: Notification
   ========================================================================== */
.notification-container {
  height: 100%;

  & .notification-table td {
    padding: 0;
  }
}

.notification-feed {
  height: 100%;

  & .dataTables_toolbar,
  & .dataTables_scrollHead,
  & .dataTables_totals,
  & .dataTables_footer {
    display: none;
  }

  & .dataTables_scroll,
  & .dataTables_wrapper {
    height: 100%;
  }

  & .dataTables_scrollBody,
  & .empty-state {
    height: calc(100vh - (#{$p21-modal-sidebar-top-spacing} + #{$p21-modal-notification-header-height})) !important;
  }
}

// Row in a table
.notification {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: $form-grid-gutter-width;
  // Border creates the illusion of extra padding to the left of the notification.
  // Design-wise it makes the unread bar stand out more.
  border-left: $p21-notification-border-width solid $modal-content-bg;
  cursor: pointer;
  opacity: 1;
  transform: translateX(0);

  // Add left bar to unread notifications
  &.is-unread {
    &:before {
      content: " ";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: $p21-notification-border-width solid darken($primary, 5%);
    }
  }

  // Slide the div out of the row when it has this class
  .slide-out-right & {
    opacity: 0;
    transform: translateX(100%);
    transition: 0.4s transform cubic-bezier(0.4, 0, 0.4, 1),0.4s opacity linear;
  }

  // Container for the text. Left contains the images.
  & .middle-container {
    margin: 0 $form-grid-gutter-width;
  }

  // Container for the action buttons
  & .right-container {
    position: relative;
    z-index: 2;
    margin-left: auto;
  }

  // The notification link
  a.notification__title {
    color: $body-color;
    word-break: break-word;
    // Allow the "click" target to span the entire notification
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .notification-table td:hover & {
    &__title {
      text-decoration: underline;
    }

    &__message,
    &__date {
      color: darken($text-muted, 20%);
    }
  }

  &__message,
  &__date {
    color: $text-muted;
  }

  &__callout {
    margin: ($form-grid-gutter-width / 2) 0;
    padding: $form-grid-gutter-width;
    color: $body-color; 
    background-color: $gray-100;
    border: $border-width solid $border-color;
  }

  & .callout-message {
    display: inline-block;
    display: -webkit-box;
    max-height: ($line-height-base * $font-size-base) * 2;
    margin: 0;
    line-clamp: 2;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & .notification__footer {
    margin-top: $form-grid-gutter-width;
  }
}
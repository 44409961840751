/* =============================================================================
// Local Variables, Mixins, and Placeholders
   ========================================================================== */
// Toggle
@mixin inputHover($color, $is-toggle: false) {
  position: absolute;
  border: none;
  border-radius: $rounded-pill;
  background: $color;
  opacity: $p21-check-hover-opacity-off;
  transform: scale(0);
  transition: $transition-base;

  @if $is-toggle {
    top: ($p21-toggle-knob-size-hover - $p21-toggle-knob-size) / -2;
    left: ($p21-toggle-knob-size-hover - $p21-toggle-knob-size - 0.06rem) / -2;
    width: $p21-toggle-knob-size-hover;
    height: $p21-toggle-knob-size-hover;
  }

  @else {
    z-index: -1;
    top: $custom-control-indicator-size / -2;
    left: $custom-control-indicator-size / -2;
    width: $custom-control-indicator-size * 2;
    height: $custom-control-indicator-size * 2;
  }
}

// Form Sections
@mixin form-border-bottom() {
  position: relative;
  border-bottom: 1px solid $white;

  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $gray-300;
  }
}

@mixin form-border-top() {
  position: relative;
  border-top: 1px solid $gray-300;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid $white;
  }
}

/* =============================================================================
// Custom Form Controllers: Defaults
//
// Default overrides and custom hover state for checkbox and radio
   ========================================================================== */
.custom-control-label {
  font-weight: $font-weight-normal;
}

.custom-control-container {
  position: relative;
  margin-bottom: $label-margin-bottom;
}

// Radio groups headers/legendinputs. (e.g., Config > Data Packages)
.radio-group-title, .form-legend {
  margin: ($label-margin-bottom * 2) 0 $label-margin-bottom;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  border: none;
}

.form-legend {
  margin-top: 0;
}

.radio-group-title:first-of-type {
  margin-top: 0;
}

/* =============================================================================
// Custom Form Controllers: Hover
//
// Custom hover circle
  ========================================================================== */
.custom-checkbox__hover, .custom-control__hover {
  @include inputHover($primary);
}

// Add hover option to react dropdowns and custom checkbox structure on React pages
.react-select__option:hover, .react-select__option:focus-visible {
  cursor: pointer;

  & .custom-checkbox__hover, & .custom-control__hover {
    opacity: $p21-check-hover-opacity-on;
    // Transform accounts for the border of the input to vertically center to the checkbox
    transform: scale(1) translateY($custom-control-indicator-border-width * 1.5);
  }
}

// Include focus for keyboard navigation and class name in React
.custom-control-input:not(:disabled):hover, .custom-control-input:focus-visible, .custom-control-label:not(:disabled):hover, .custom-control-label:focus-visible {

  & ~ .custom-checkbox__hover, & ~ .custom-control__hover {
    opacity: $p21-check-hover-opacity-on;
    //Transform accounts for the border of the input to vertically center to the checkbox
    transform: scale(1) translateY($custom-control-indicator-border-width * 1.5);
  }
}

.custom-control-input:hover, .custom-control-label:hover, .custom-control .help-block:hover, .dropdown-menu >:hover {
  cursor: pointer;
}

.custom-control-input:disabled, .custom-control-input:disabled ~ .custom-control-label, .disabled {
  pointer-events: none;
  cursor: default;
}

/* =============================================================================
// Custom Form Controllers: Help
//
// Help text
  ========================================================================== */
.custom-control-container .form-text {
  margin: 0 0 0 ($custom-control-gutter + $custom-control-indicator-size);
}

.custom-control .form-text {
  display: inline-block;
  width: 100%;
  margin-top: 0;
}

.custom-control:last-of-type .form-text {
  margin: 0;
}

.help-text + .help-link {
  margin-left: 0.25rem;
}

/* =============================================================================
// Custom Form Controllers: Inline
//
// Style variation to display inline controllers as chips.
   ========================================================================== */
.custom-control-container--inline {
  display: inline-block;
  margin-right: $spacer/2;
  margin-bottom: $spacer/2;
  padding: 0;

  & .custom-control-inline {
    margin: 0;
    padding: 0;
  }

  & .custom-control-label {
    @include gradient-y-three-colors($white, $gray-100, 40%, $gray-300);
    padding: $input-btn-padding-y-sm $btn-padding-x ($input-btn-padding-y-sm - $p21-border-width-in-rem) calc(calc(#{$btn-padding-x} / 2) + #{$custom-control-gutter} + #{$custom-control-indicator-size} + #{$border-width});
    border: $btn-border-width solid $p21-select-choice-border-color;
    border-radius: $btn-border-radius;
    transition: $transition-base;

    // in Datatables toolbar, match the inline custom control to the buttons
    .dataTables_toolbar & {
      @include gradient-y($start-color: $light, $end-color: darken($light, 10%), $start-percent: 0%, $end-percent: calc(100% - #{$p21-btn-bg-pos-y}));
      box-shadow: inset 0 1px 0 rgba($white, 0.2), inset 0 1px 2px rgba($black, 0.05);
      background-size: 100% calc(100% + #{$p21-btn-bg-pos-y});
      border-color: $p21-btn-border-color-datatable;
      border-radius: $btn-border-radius-sm;
      font-weight: $p21-font-weight-semi-bold;
      text-shadow: 0 1px 1px rgba($white, 0.75);
      &:hover {
        background-position: 0 ($p21-btn-bg-pos-y * -1);
      }
    }
  }

  & .custom-control-input:checked ~ .custom-control-label {
    border-color: $custom-control-label-checked-border-color;
    background: $custom-control-label-checked-bg-color;
    color: $primary-900;
  }

  & .custom-control-label:hover {
    border-color: $primary;
  }

  & .custom-control-label::before, 
  & .custom-control-label:after {
    z-index: 3;
    top: calc(#{$input-btn-padding-y-sm} + #{$border-width});
    left: $custom-control-gutter;
  }

  // dim disabled items 
  & .custom-control-input:disabled ~ .custom-control-label {
    opacity: .4;
  }

  // make sure inline control indicators get the appropriate background colors when checked
  & .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: $custom-control-indicator-inline-checked-bg; 
    border-color: $custom-control-indicator-checked-border-color;
  }

  & .custom-control__hover {
    z-index: 2;
    top: calc((#{$custom-control-indicator-size} / -2) + #{$input-btn-padding-y-sm});
    left: calc((#{$custom-control-indicator-size} / -2) + #{$custom-control-gutter} + #{$border-width});
    pointer-events: none;
  }

  // Small size for inline custom controllers. scaled down the checkbox and the control hover. 
  &.custom-control-container--sm {
    margin: 0;

    & .custom-control-label {
      font-size: $input-btn-font-size-sm;
      padding-right: $input-btn-padding-x-sm;
      padding-left: ($input-btn-padding-x-sm + $custom-control-indicator-size);
      padding-bottom: $input-btn-padding-y-sm;
    }

    & .custom-control-label::before, 
    & .custom-control-label:after {
      top: calc((#{$input-btn-padding-y-sm} / 2));
      left: $input-btn-padding-x-sm;
      transform: scale(.8) translateX(calc(#{$input-btn-padding-x-sm / -2}));
    }

    & .custom-control__hover {
      top: calc(#{$custom-control-indicator-size} / -2);
      left: calc((#{$custom-control-indicator-size} / -2) + #{$input-btn-padding-x-sm});
    }

    & .custom-control-input:not(:disabled):hover, 
    & .custom-control-input:focus-visible, 
    & .custom-control-label:not(:disabled):hover, 
    & .custom-control-label:focus-visible {

      // scale the hover transform to keep the animation/grow
      & ~ .custom-checkbox__hover, & ~ .custom-control__hover {
        transform: scale(.8) translate(calc(#{$input-btn-padding-x-sm / -2}), 3px); //3px is a magic number cheat as the doubling the border width was still visually looked a pixel off
      }
    }

  }
}

/* =============================================================================
// Custom Form Controllers: Radio button answer key
//
// Display correct or incorrect answers on quizzes, forms, etc.
   ========================================================================== */
// Add more types here for custom styling names
$answer-types:  "correct", "wrong";

.answer-key {
  display: none;
  position: absolute;
  z-index: 2;
  top: ($font-size-base * $line-height-base - $custom-control-indicator-size) / 2;
  left: -($custom-control-gutter + $custom-control-indicator-size);
  display: block;
  width: $custom-control-indicator-size;
  height: $custom-control-indicator-size;
  font-size: $custom-control-indicator-size;
  line-height: 1;
  pointer-events: none;

  & .icon {
    display: none;
  }

  &--legend {
    top: ($h3-font-size * $headings-line-height - $h3-font-size) / 2;
    width: $h3-font-size;
    height: $h3-font-size;
    font-size: $h3-font-size;
  }
}

// Show the icon that represents the correct/incorrect answer for the radio button
@each $type in $answer-types {
  .is-#{$type} {
    &.answer-key,
    & .icon--#{$type} {
      display: block;
    }
  }
}

/* =============================================================================
// Fieldsets:
//
// Found in quizzes, etc
   ========================================================================== */
.control-fieldset {
  &--legend {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-size: $h3-font-size;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
  }

  & .error-message {
    display: none;
  }

  &.has-error {
    outline: $border-width solid $danger;
    outline-offset: 0.75rem;

    & .error-message {
      display: block;
    }
  }
}

/* =============================================================================
// Toggle switch: Reset
//
// Style variation for the checkbox.
// Hide default styling and position the container.
   ========================================================================== */
.custom-toggle-container {
  @include form-border-bottom;
  padding: $p21-form-group-spacing-y / 2 0;

  &:first-of-type {
    padding-top: 0;
    margin-top: $p21-form-group-spacing-y / 2;
  }

  &.hide-label {
    display: inline-block;
    border: none;

    &::after {
      display: none;
    }

    & .custom-control-label {
      @include sr-only();
    }
  }
}

.custom-toggle-container--inline {
  border: none;

  &::after {
    display: none;
  }

  & .custom-toggle {
    display: inline-flex;
  }
}

// Toggle switch label
.custom-toggle {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  & .custom-control-label:before, & .custom-control-label:after {
    display: none;
  }
}

// Toggle switch label
.custom-toggle .custom-control-label {
  margin-right: $label-margin-bottom;
}

// Toggle switch input
.custom-toggle .custom-control-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;

  // Background when checked
  &:checked ~ .custom-control__icon {
    border-color: $primary;
    background: $primary;
  }

  // Knob position when checked
  &:checked ~ .custom-control__icon:after {
    left: $p21-toggle-position-left-on;
    background-image: escape-svg($custom-checkbox-indicator-icon-checked-color);
  }

  // Hover position when checked
  &:checked ~ .custom-control__icon:before {
    left: calc(100% - ((#{$p21-toggle-knob-size-hover} + #{$p21-toggle-knob-size}) / 2 + #{$input-border-width}));
  }

  // Hide div that is hover because we can't get it inbetween the knob and the background
  & .custom-control__hover {
    display: none;
  }

  // Focus state
  &:focus-visible ~ .custom-control__icon {
    border-color: $custom-control-indicator-focus-border-color;
    box-shadow: $custom-control-indicator-focus-box-shadow;
  }

  &:focus-visible ~ .custom-control__icon::before {
    opacity: $p21-check-hover-opacity-on;
    transform: scale(1);
  }
}

/* =============================================================================
// Toggle switch: Default
//
// Default state for the background and knob
   ========================================================================== */
// Toggle background
.custom-toggle .custom-control__icon {
  position: relative;
  display: inline-block;
  min-width: $p21-toggle-width;
  width: $p21-toggle-width;
  height: $p21-toggle-height;
  margin: 0 0 0 auto;
  padding: 0;
  background: $input-disabled-bg;
  border: $input-border-width solid $border-color;
  border-radius: $rounded-pill;
  pointer-events: none;
  transition: $p21-toggle-transition;

  // Toggle knob
  &:after {
    content: '';
    position: absolute;
    top: $p21-toggle-position-top;
    left: $p21-toggle-position-left-off;
    width: $p21-toggle-knob-size;
    height: $p21-toggle-knob-size;
    border-radius: $rounded-pill;
    background-color: $white;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center center;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 3px 0px;
    transition: $p21-toggle-transition;
  }
}

/* =============================================================================
// Toggle switch: Hover
//
// Hover state
   ========================================================================== */
.custom-toggle .custom-control__icon:before {
  content: '';
  @include inputHover($primary, true);
  transition: transform .2s ease-in-out, opacity .2s ease-in-out, left 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.custom-toggle:hover .custom-control__icon:before {
  opacity: $p21-check-hover-opacity-on;
  transform: scale(1);
}


/* =============================================================================
// Form Groups: Custom controllers
//
// Styling to center align the required/option attribute to the group
  ========================================================================== */
.custom-control-group {
  display: flex;
  flex-direction: column;
}

.custom-control-group--horizontal {
  flex-direction: row;
  padding-top: 7px;
}

.custom-control-group--horizontal .custom-control-group__required {
  order: 1;
  align-self: center;
  flex: 1 1 auto;
}

.custom-control-group--horizontal .custom-control-group__inputs {
  order: -1;
  flex: 0 0 auto;
}


/* =============================================================================
// Form Section: Popovers
//
// Styling (mostly layout) for section headers and input groups
  ========================================================================== */
.form-popover-container {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.form-popover {
  position: relative;
  top: 0;
  flex: 1 1 50%;
  display: block;
  max-width: 190px;
  width: 0;
  margin: 0 0 ($spacer * 3) $spacer;
  // Allow breaking very long words so they don't overflow the popover's bounds
  word-wrap: break-word;
  background-color: $gray-200;
  background-clip: padding-box;
  border: $popover-border-width solid $popover-border-color;
  border-radius: $popover-border-radius;
  opacity: 0;
  pointer-events: none;
  transition: opacity .15s ease-in-out, top .3s ease-in-out;

  .arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $popover-border-radius;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  &.show {
    display: block;
    opacity: 1;
    width: 100%;
    pointer-events: all;
  }

  &.is-3rem-down {
    top: 3rem;
  }

  &.is-6rem-down {
    top: 6rem;
  }

  &__body {
    padding: $popover-body-padding-y;
    color: $popover-body-color;
  }
}

.form-popover--right {

  > .arrow {
    left: subtract(-$popover-arrow-height, $popover-border-width);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $popover-border-radius 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      left: 0;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-right-color: $popover-arrow-outer-color;
    }

    &::after {
      left: $popover-border-width;
      border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
      border-right-color: $gray-200;
    }
  }
}

.form-popover .form-horizontal {

  & .form-group {
    display: flex;
    margin: 0 0 $p21-form-group-spacing-y;
    padding: 0;
    border: none;
  }

  & .form-group:last-of-type {
    margin-bottom: 0;
  }

  & .col-form-label {
    flex: 1 1 40%;
    margin-right: $label-margin-bottom;
    font-weight: $font-weight-bold;
    text-align: right;
  }
}


/* =============================================================================
  // Form Sections
   ========================================================================== */
.form-section {
  margin-top: $p21-form-section-spacing-y;
  padding: 0 $form-grid-gutter-width;

  // Space in between sections
  &:first-of-type,
  form > &:first-child, 
  form > *:first-child &:first-child, 
  .tab-content-sidebar .widget-content > &:first-child,
  .widget-content > &:first-child {
    margin-top: $p21-form-section-spacing-y / 2;
    padding-top: 0;
  }

  &:last-of-type {
    margin-bottom: $p21-form-section-spacing-y / 2;
  }

  // Group inputs
  & .form-group {
    border: none;
    margin-bottom: $p21-form-group-spacing-y;
    padding: 0;
  }

  // Group inputs
  & .form-group:first-of-type {
    margin-top: $p21-form-group-spacing-y / 2;
  }

  .modal-body__form & .form-group {
    @include media-breakpoint-down(xs) {
      margin-bottom: $form-grid-gutter-width;
    }
  }
}

.form-section-header {
  @include form-border-bottom;
  display: flex;
  align-items: baseline;
  flex: 0 0 100%;
  margin-bottom: $p21-form-group-spacing-y;
  line-height: $headings-line-height;

  &__title {
    @include font-size($h3-font-size);
    flex: 1 2 20%;
    margin: 0 $spacer ($label-margin-bottom / 2) 0;
    color: $gray-800;
    font-weight: $headings-font-weight;
  }

  &__link {
    margin: 0 0 ($label-margin-bottom / 2) auto;
    cursor: pointer;
  }

  &__help {
    margin: 0 0 ($label-margin-bottom / 2) auto;
    color: $text-muted;
    line-height: $headings-line-height;
  }
}

/* =============================================================================
// Form Sections: Horizontal
//
// Inline layout styling for form section headers
   ========================================================================== */
@include media-breakpoint-up(sm) {

  .form-section-horizontal {
    @include form-border-top;
    display: flex;
    margin: 0 $form-grid-gutter-width;
    padding: $p21-form-group-spacing-y 0 0;

    // Remove border in header
    & .form-section-header {
      display: block;
      flex: 1 1 25%;
      padding: 0;
      margin-right: $spacer;
      border-bottom: none;
    }

    & .form-section-header__help {
      line-height: $line-height-base;
    }

    & .form-section-header:after {
      display: none;
    }

    & .form-section-body {
      flex: 1 1 75%;
    }

    & .form-section-body>*:first-child {
      margin-top: 0;
    }
  }

  form>.form-section-horizontal:first-of-type {
    border-top: none;

    &::before {
      display: none;
    }
  }
}

/* =============================================================================
// Form Sections: Overrides
   ========================================================================== */
.form-section--cards {
  & .form-section-header {
    margin: 0 ($spacer / 2) ($p21-form-group-spacing-y / 2);
  }
}

/* =============================================================================
  // Form Groups
   ========================================================================== */
.form-group--inline {
  position: relative;

  // Styling for Dictionaries section
  & .custom-toggle-container {
    position: absolute;
    top: 0;
    left: calc(17% + 350px + 20px); // left over .col-md-10 width + input width + (.col left-padding * 2 - this left padding)
    padding: 10px;

    @include media-breakpoint-down(sm) {
      left: calc(25% + 350px + 20px); // left over .col-sm-9 width + input width + (.col left-padding * 2 - this left padding)
    }

    @include media-breakpoint-down(xs) {
      top: auto;
      left: auto;
      right: 0;
      bottom: $border-width * 2; 
    }
  }

  & .has-error .client-error,
  & .has-error .server-error {
    display: block;
    margin-top: $label-margin-bottom;
  }
}


/* =============================================================================
// Form Sidebar
//
// Layout styling when a form has a read-only sidebar to the right
   ========================================================================== */
.tab-content.has-sidebar, .has-sidebar {
  display: flex;

  & .form-container {
    flex: 1 1 auto;
  }

  & .tab-content-sidebar {
    flex: 0 1 auto;
    max-width: $modal-md;
    margin-left: $grid-gutter-width;
  }

  @include media-breakpoint-down(sm) {
    & .tab-content-sidebar {
      max-width: $modal-sm;
    }
  }

  @include media-breakpoint-down(xs) {
    display: block;

    & .tab-content-sidebar {
      max-width: 100%;
      margin-left: 0;
    }
  }
}


/* =============================================================================
// Form Sidebar: Read-Only Elements
//
// Styling for read-only elements in the form sidebar
   ========================================================================== */
.form-group-read-only {
  margin-top: $spacer;
  padding: $spacer * 0.75;
  background: $white;
  border: $border-width solid $border-color;
  border-radius: $input-border-radius;

  &:first-of-type {
    margin-top: $p21-form-group-spacing-y / 2;
  }

  &__title {
    margin: 0 0 $label-margin-bottom 0;
    font-size: $h6-font-size;
    font-weight: $headings-font-weight;
  }

  & .custom-toggle {
    width: 0;
    height: 0;
  }

  & > .label {
    margin-right: $spacer / 2;
    line-height: 2.3;
  }

  & > .label-primary {
    background-color: $primary;
  }
}


/* =============================================================================
  // Horizontal form groups that have stacked labels
   ========================================================================== */
.form-group-input-column {
  margin-left: 16.666666666666664%;
  padding-left: 15px;
}


/* =============================================================================
// Component Specific Styling:
//
// Roles / User Permissions
   ========================================================================== */
.user-permissions {
  &__option {
    @include form-border-bottom;
    padding: $p21-form-group-spacing-y / 2 0;
  }

  &__option:first-of-type {
    padding-top: 0;
    margin-top: $p21-form-group-spacing-y / 2;
  }
}

.user-permissions-read-only {
  padding: 0;
  margin: 0;

  &__option {
    display: flex;
    align-items: baseline;
    margin-bottom: $spacer / 3;
    color: $text-muted;
  }

  &__icon {
    display: inline-block;
    width: $font-size-base;
    margin-right: $spacer / 2;
    font-size: $font-size-base;
    color: $success;
    opacity: 0;
  }

  // Show checkmark if the user has that permission
  & .custom-control-input:checked + .user-permissions-read-only__option .user-permissions-read-only__icon,
  & .user-permissions-read-only__icon.show {
    opacity: 1;
  }

  & .custom-control-input:checked + .user-permissions-read-only__option {
    color: $body-color;
  }
}

/* =============================================================================
// Component Specific Styling:
//
// Avatar / Profile Picture
   ========================================================================== */
.custom-avatar {
  display: flex;
  align-items: center;
  padding: $input-padding-y $input-padding-x;
  background: $input-bg;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  box-shadow: $input-box-shadow;

  // Currently in forms this is the standard input width
  .input-width--md & {
    max-width: $p21-input-width-md;

    @media (min-width: 992px) and (max-width: 1199px) {
      max-width: none;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      max-width: $p21-input-width-md;
    }
  }
  @media (max-width: 767px) {
    .hidden-xs {
      display: none !important;
    }
  }

  &.is-disabled {
    background: $input-disabled-bg;
  }

  &__image {
    flex: 0 0 90px;
    width: 90px;
    height: 90px;
  }

  &__info {
    flex: 1 1 auto;
    margin-left: $spacer;
  }

  &__instructions {
    display: block;
    margin-top: $label-margin-bottom;
    color: $text-muted;
  }

  &__error-message {
    display: inline;
  }
}

/* =============================================================================
// Component Specific Styling:
//
// Multiselect File Upload
  ========================================================================== */
.multiselect-upload-container {
  width: 77%;
  min-width: 350px;
  height: 300px;
}

.multiselect-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &--empty-state {
    border: $border-width solid $gray-400;
    border-radius: $input-border-radius;
  }
}

.multiselect-upload__header {
  display: flex;
  padding: $table-cell-padding;
  background: $gray-100;
  border: $border-width solid $gray-400;
  border-radius: $input-border-radius $input-border-radius 0 0;

  & .btn-add-remove {
    display: inline-block;
    cursor: pointer;
  }

  & .btn-add-remove.disabled {
    display: inline-block;
    cursor: default;
    color: $body-color;
    opacity: $btn-disabled-opacity;
  }
}

.multiselect-upload__body {
  height: 100%;
  max-height: 250px;
  overflow-y: auto;
  padding: 0 $table-cell-padding;
  background: $white;
  border-left: $border-width solid $gray-400;
  border-right: $border-width solid $gray-400;
}

.multiselect-upload__row {
  position: relative;
  display: flex;
  align-items: center;
  padding: $table-cell-padding 0;
  border-bottom: $border-width solid $gray-400;

  &:last-of-type {
    border-bottom: none;
  }
}

.multiselect-upload__cell {
  margin-right: $table-cell-padding;

  &--file-path {
    flex: 1 1 55%;
    text-align: left;
    word-break: break-all;
  }

  &--file-size {
    flex: 0 1 20%;
    text-align: right;
  }

  &--action {
    flex: 0 1 20%;
    max-width: 5rem;
    margin-left: auto;
    margin-right: 0;
    text-align: right;
  }

  &--help-text {
    margin-left: auto;
    text-align: right;
  }

  & .remove-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    margin-left: auto;
    padding: $input-padding-y-sm;
    color: $custom-select-indicator-color;
    border-radius: 50%;
    cursor: pointer;
    transition: $badge-transition;
  }

  & .remove-item:hover {
    color: $danger;
    background-color: rgba($danger, 0.15);
  }
}

.multiselect-upload__footer {
  display: flex;
  align-items: center;
  padding: $table-cell-padding;
  background: $gray-100;
  border: $border-width solid $gray-400;
  border-radius: 0 0 $input-border-radius $input-border-radius;

  & .message {
    display: block;
  }

  & .message--sm {
    font-size: $font-size-sm;
  }
}

/* =============================================================================
// Component Specific Styling:
//
// Multiselect File Upload - Error State
  ========================================================================== */
.has-error {

  & .multiselect-upload__header, & .multiselect-upload__body, & .multiselect-upload__footer {
    border-color: $danger;
  }

  &.multiselect-upload__row {
    color: $black;
    font-weight: $font-weight-bold;
  }

  &.multiselect-upload__row::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% + (#{$table-cell-padding} * 2));
    top: $border-width * -1;
    bottom: 0;
    left: $table-cell-padding * -1;
    background-color: rgba($danger, 0.10);
    pointer-events: none;
  }

  &.multiselect-upload__cell > .message-error {
    color: $danger;
    font-weight: $font-weight-bold;
  }

  &.multiselect-upload__row .remove-item {
    color: $black;
  }

  &.multiselect-upload__row .remove-item:hover {
    color: darken($danger, 10%);
  }

}

.has-warning .multiselect-upload {

  &__cell--help-text {
    color: darken($warning, 10%);
  }
}

/* =============================================================================
   Local variables
   ========================================================================== */
$p21-avatar-size: 36px;
$p21-comment-gutter-width: 4px;
$p21-comment-icon-size: 4rem;
$p21-comment-textarea-height: 100px;

/* =============================================================================
   Comment styles
   ========================================================================== */
.comments {
  position: relative;
  height: 100%;
  min-height: ($spacer * 8) + $p21-comment-icon-size;
  width: 100%;
  background: $gray-200;

  &__title {
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    box-shadow: 0px 3px 3px -3px rgba(0,0,0,0.25);
  }

  // only apply flex to center the empty state when it is split in two columns
  &.is-empty {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}

.comment {
  display: flex;
  margin-bottom: $spacer;
  padding-top: $p21-comment-gutter-width;

  @include media-breakpoint-up(md) {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .avatar {
    border-radius: 50%;
    width: auto;
    height: $p21-avatar-size;
    margin-right: $p21-comment-gutter-width * 2;
  }

  &-author {
    font-weight: $p21-font-weight-semi-bold;
  }

  &-date {
    margin-left: $p21-comment-gutter-width * 2;
    color: #42526e;
  }

  &-edited {
    margin-left: $p21-comment-gutter-width * 2;
  }

  &-body {
    width: 100%;
  }

  &-message {
    padding: $p21-comment-gutter-width ($p21-comment-gutter-width * 2);
    border-radius: $input-border-radius;
    background: $white;
  }

  &-content {
    margin-top: $p21-comment-gutter-width;
  }

  &-mention {
    background-color: #ddd;
    border-radius: 20px;
    padding: 0 $p21-comment-gutter-width ($p21-comment-gutter-width / 2) $p21-comment-gutter-width;
  }

  &-actions {
    display: flex;
    margin-top: $p21-comment-gutter-width;

    &-button {
      display: flex;

      & + ::before {
        color: #42526e;
        content: "·";
        display: inline-block;
        text-align: center;
        width: 16px;
      }

      button {
        padding: 0;
        border: none;
      }
    }
  }

  // Container for adding a new comment
  &--add-new {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    background: $gray-200;
    box-shadow: 0px -3px 3px -3px rgba(0,0,0,0.25);

    @include media-breakpoint-down(sm) {
      margin-bottom: $spacer;
    }
  }

  // Container for the list of comments
  &-feed {
    position: relative;
    overflow-y: auto;
    height: calc(100% - #{$p21-avatar-size} - #{$p21-comment-gutter-width} - #{$spacer} * 1.5);
    width: 100%;
    padding: $spacer / 2;
    padding-left: 0;

    @include media-breakpoint-down(sm) {
      height: calc(100% - #{$p21-avatar-size} - #{$p21-comment-gutter-width} - #{$spacer} * 2.5);
    }
  }

  &-editor {
    position: relative;
    z-index: 1;
    width: inherit;

    &__control {
      width: inherit;
    }

    &__input {
      @extend .form-control;
    }

    &--singleLine {
      .comment-editor__highlighter {
        height: $input-height !important;
        padding: $input-padding-y $input-padding-x;
        transition: $transition-collapse;
      }

      .comment-editor__input {
        height: $input-height !important;
        background-color: #fff !important;
      }
    }

    &--multiLine {
      .comment-editor__highlighter {
        height: $p21-comment-textarea-height !important;
        padding: 7px 13px;
        border-radius: $input-border-radius;
        transition: $transition-collapse;

        strong {
          background-color: #ddd;
        }
      }

      .comment-editor__input {
        height: $p21-comment-textarea-height !important;
        overflow: auto !important;
        background-color: #fff !important;
      }
    }

    &__suggestions {
      &__list {
        border: 1px solid $input-border-color;
        max-height: 193px;
        overflow: auto;
      }

      &__item {
        padding: 5px 15px;
        border-bottom: 1px solid $input-border-color;

        &--focused {
          color: #fff;
          background-color: #3875d7;
        }
      }
    }

    &-actions {
      padding-top: 12px;
    }
  }
}

/* =============================================================================
   Empty state
   ========================================================================== */
.comments .empty-state {
  padding: calc(#{$spacer} / 2);
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
    height: $p21-comment-icon-size;
    width: $p21-comment-icon-size;
    background: $gray-300;
    border-radius: 50%;

    @include media-breakpoint-down(sm) {
      height: $p21-comment-icon-size * .75;
      width: $p21-comment-icon-size * .75;
    }
  }

  &__image--lg {
    height: auto;
    width: auto;
    background: none;
    border-radius: 0;
  }

  &__icon {
    font-size: $p21-comment-icon-size / 2;
    color: $gray-500;

    @include media-breakpoint-down(sm) {
      font-size: ($p21-comment-icon-size / 2) * .75;
    }
  }

  &__message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $text-muted;
    text-align: center;
  }

  &__title {
    margin: 0.5rem 0 0.25rem;
    color: $text-muted;
    font-size: 1rem;

    @include media-breakpoint-down(sm) {
      margin: 0 0 0.1rem;
    }
  }
}


/* =============================================================================
   Comments Overrides: Spec Chat
   ========================================================================== */
// Left Section in the modal toolbar
.thread-location {
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;
  vertical-align: text-top;
  cursor: default;

  &__path {
    flex-basis: 100%;
    display: block;
    font-size: $font-size-sm;
  }

  //&__path-divider {
    //margin: 0 0.25rem;
    //font-size: $font-size-sm;
  //}

  &__current {
    display: inline-block;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// Right Section in the modal toolbar
// Sub-section of right
.thread-navigation {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  & .thread-count {
    margin: 0 0.25rem;
    line-height: 1;
  }
}

.thread-actions {
  .is-resolved & .bt-icon--resolve .icon {
    color: $success;
  }
}

// Thread body in the sidebar
.chat-thread {
  & .comments {
    display: flex;
    flex-direction: column;
    background-color: transparent;
  }

  & .comment-feed {
    height: 100%;
    padding: 0 $p21-modal-sidebar-inner-padding;

    .is-resolved &,
    .is-archived & {
      height: 100%;
      opacity: 0.7;
    }
  }

  & .comment:first-of-type {
    margin-top: $p21-comment-gutter-width * 2;
  }

  & .comment-message {
    padding: 0;
    background: none;
  }

  & .comment-actions {
    .is-resolved &,
    .is-archived & {
      display: none;
    }
  }

  & .comment--add-new,
  & .comment--disabled {
    position: relative;
    width: 100%;
    margin: 0;
    padding: $p21-modal-sidebar-inner-padding;
    background: $gray-200;
    border-top: $border-width solid $border-color;

    .is-resolved &,
    .is-archived & {
      display: none;
    }
  }

  & .comment--disabled {
    text-align: center;
  }
  
}
/* =============================================================================
// Local Variables, Mixins, and Placeholders
  ========================================================================== */
// Add more width sizes here for custom styling (first value is class name form-control---[string], second value is css width %, px, rem, etc)
// !!! Note: these names will be prepended with "form-control--"
$input-widths: (
  "xs": $p21-input-width-xs,
  "sm": $p21-input-width-sm, 
  "md": $p21-input-width-md, 
  "lg": $p21-input-width-lg, 
  "w-350": 350px
);

%validation-text-color {
  .has-error & {
    color: $danger;
  }

  .has-success & {
    color: $success;
  }

  .has-warning & {
    color: darker($warning, .10);
  }
}


/* =============================================================================
   Forms Base: Labels
   ========================================================================== */
label {
  font-weight: $font-weight-bold;
}

.col-form-label {
  @extend %validation-text-color;
  font-weight: $font-weight-bold;
  color: $gray-700;
  text-shadow: 0 1px 0 $white;
  text-align: right;

  @include media-breakpoint-down(xs) {
    text-align: left;
  }
}

// Required/optional text (help-inline)
.help-inline {
  @extend %validation-text-color;
  display: inline-block;
  //margin: 0 0 0 $form-grid-gutter-width;
  margin: 0;
  color: $gray-700;
}


/* =============================================================================
   Forms Base: Help Text
   ========================================================================== */
.form-text {
  @extend %validation-text-color;
  font-weight: $font-weight-normal;
  color: $text-muted;
}

// Error messages 
.client-error,
.server-error {
  @extend %validation-text-color;
  display: none;
  font-weight: $font-weight-normal;
}


/* =============================================================================
   Forms Base: Inputs
   ========================================================================== */
.form-group {
  margin-left: 0;
  margin-right: 0;
  padding: $form-grid-gutter-width 0;
  border-top: $border-width solid $p21-form-group-border-top-color;
  border-bottom: $border-width solid $p21-form-group-border-bottom-color;

  &:nth-child(2):not(:only-child) {
    border-top: none;
  }

  &:last-child:not(:only-child) {
    border-bottom: none;
  }
}

.form-control {
  // Add spacing to the right for required/optional text
  margin-right: $form-grid-gutter-width;

  &:not(:read-only):hover {
    border-color: $input-focus-border-color;
  }

  &:read-only{
    cursor: default;
  }

  // Remove spacing when it has an append to it like a Browse button 
  .input-group & {
    margin-right: 0;
  }

  .has-error & {
    border-color: $danger;
  }

  .has-warning & {
    border-color: $warning;
  }
}

// Search input
input[type=search i]::-webkit-search-cancel-button {
  margin-right: 0.25rem;
  cursor: pointer;
}

// hide the little 'x' that shows in the input field in ie
::-ms-clear {
  display: none;
}

// File browse buttons or similar inputs
.input-group-append,
.input-group-prepend {

  & label {
    margin-bottom: 0;
  }

  .has-error &,
  .has-warning & {
    color: $white;
  }
}


/* =============================================================================
// Forms Overrides: Sizes
//
// Add custom input width classes to the input-widths map at the top of this code
   ========================================================================== */
// Similiar to modal sizes. This dynamically creates new class names and widths based on the mapping at the top
@each $name, $size in $input-widths {
  .input-width--#{$name} {
    & .form-control,
    & .custom-control-group__inputs {
      display: inline-block;
      vertical-align: middle;
      width: $size;
    }

    // Separate the help text because it needs to stay as block to remain underneath
    & .form-text {
      width: $size;
    }
  }
}

// Styling for extra small inputs
.input-width--xs {
  width: $p21-input-width-xs;

  /* Hide up and down arrows for xs number inputs */
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none !important; 
    margin: 0 !important; 
  }

  &[type=number] {
    -moz-appearance: textfield !important;
    appearance: textfield !important;
  }
}


/* =============================================================================
// Form Overrides: Footer Calls to Action
//
// (e.g. buttons and links to perform actions)
   ========================================================================== */
.form-actions {
  display: flex;
  align-items: center;
  padding: $form-grid-gutter-width $spacer $form-grid-gutter-width calc(16.666666666666664% + (#{$grid-gutter-width} / 2));
  margin-bottom: $form-grid-gutter-width;
  background: $white;
  border: $border-width solid $border-color;
  box-shadow: $p21-widget-box-shadow;

  .widget-box & {
    margin-bottom: 0;
    border-left: none;
    border-right: none;
    border-bottom: none;
    box-shadow: none;
  }

  & .btn {
    margin-right: $spacer;
  }
}

.widget-content--form .form-actions {
  justify-content: center;
  padding: $form-grid-gutter-width $spacer;
  margin-bottom: 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
  box-shadow: none;

  &__primary {
    flex: 1 1 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
  }

  &__secondary {
    order: -1;
    flex: 0 0 auto;
    margin: 0 $spacer 0 0;
    padding-right: $spacer;
    border-right: $border-width solid $border-color;
    white-space: nowrap;

    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column;

      & > * {
        margin-bottom: $spacer / 2;
      }

      & > *:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & .btn {
    margin: 0;
  }

  & .btn-primary--save {
    min-width: 175px;
    margin-right: $spacer; // This prevents the button from overlapping with the save reminder text on smaller res

    @include media-breakpoint-down(xs) {
      margin: 0;
    }
  }

  & .save-reminder {
    order: -1;
    position: absolute;
    top: 50%;
    left: 0;
    margin: 0 $spacer / 2 0 0;
    color: $text-muted;
    opacity: 1;
    transform: translateY(-50%);
    transition: opacity 0.5s;
  }

  & .btn-primary--save:disabled ~ .save-reminder {
    opacity: 0;
  }
}

.is-disabled {
  opacity: $btn-disabled-opacity;
  cursor: default;

  & > *, &.has-tooltip .has-tooltip {
    pointer-events: none;
  }

  & .has-tooltip {
    pointer-events: all !important;
  }
}

// (Deprecated) Graceful fallback : Replace with .form-actions { .form-actions__primary, .form-actions__secondary}
.form-shortcuts {
  display: flex;

  & ul {
    margin: 0;
    padding: 0;
  }

  & li {
    display: inline-block;
  }
}


/* =============================================================================
// Form Overrides: External License Layout
//
// Admin > Settings > Company Settings Tab
   ========================================================================== */
.external-license-agreement {
  display: flex;
  padding: $spacer;

  &__terms {
    flex: 1 1 60%;
    margin-right: 2rem;
  }

  &__inputs {
    flex: 1 0 40%;
  }

  &__inputs .custom-control-container {
    margin-bottom: $spacer;
  }

  &__inputs .custom-control-container:last-of-type {
    margin-bottom: 0;
  }
}

/* =============================================================================
// Form Overrides: Static or Read-Only horizontal fields
//
// Admin > System Status 
   ========================================================================== */
.static-field {
  padding-top: add($input-padding-y, $input-border-width);
  padding-bottom: add($input-padding-y, $input-border-width);
  margin-bottom: 0; 
  @include font-size(inherit);
  line-height: $input-line-height;
}

.status-badge {
  & i:first-child {
    margin-right: 0.5rem;
  }
}

input[type='file'][hidden] {
  display: none;
}


/* =============================================================================
// Form Overrides: Where Clause Builder
   ========================================================================== */
.range-check {
  margin-bottom: $form-grid-gutter-width; 

  &:last-of-type {
    margin-bottom: 0;
  }
}
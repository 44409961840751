.badge {
  text-shadow: none;
}

.badge-warning, .badge-medium, .badge-to-close, .badge-p21, .badge-source, .badge-neutral {
  color: $white;
}

.badge-circle {
  border-radius: 50%;
  font-size: 1em; 
  height: 1.5em;
  width: 1.5em;
}
/* =============================================================================
   List Group Base
   ========================================================================== */
.list-group {
  padding: 0;
  list-style-type: none;

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: $spacer;
  }

  &__item-title {
    font-weight: $font-weight-bold;
  }

  &__item-icon {
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem;
    margin-right: $spacer / 2;
    font-size: 1rem;
    color: $gray-600;
    text-align: center;
    line-height: 2rem;
    background-color: $gray-200;
    border-radius: $border-radius;
  }

  &__item-status {
    font-size: $font-size-sm;
    word-break: break-word;
  }
}

/* =============================================================================
   List Group Overrides
   ========================================================================== */
// List of warnings and errors in modals after an fails to complete   
.progress-feedback {
  overflow-y: auto;
}

.progress-feedback--300h {
  max-height: 300px;
}

.progress-feedback li {
  padding: 0.313rem 0.625rem;
}


/* =============================================================================
   List Group Overrides: Users (e.g. Request Assignment)
   ========================================================================== */
.user-list {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: $popover-body-padding-x * -1;

  &__row {
    display: flex;
    align-items: center;
    padding: $popover-body-padding-y $popover-body-padding-x;
    border-bottom: $border-width solid $border-color;
  }

  &__row:last-of-type {
    border: none;
  }

  &__id {
    margin-left: $spacer / 4;
  }

  &__details {
    color: $text-muted;
    font-size: $p21-font-size-semi-sm;
  }

  & .left-container {
    display: flex;
    margin-right: $spacer * 2;
  }

  & .right-container {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

.list-item--preference {
  align-items: center;
  margin: $spacer;
  
  &:not(:last-of-type),
  &:first-of-type {
    @include form-border-bottom;
  }
}

/* =============================================================================
   Item Group - used with <ul>
   ========================================================================== */

// TODO: will reconcile with the .user-list component. For now, new general component.

.list-items {
  $self: &; // use to get correct scope with nested modifiers https://css-tricks.com/using-sass-control-scope-bem-naming/
  margin-bottom: $spacer;
  padding: 0;
  list-style-type: none;
  a {
    color: $body-color;
  }
  &__item {
    margin: 0;
    padding: calc(#{$spacer} * .65) 0;
    position: relative;
    // the border/divider
    + #{$self}__item::before {
      border-bottom: 1px solid rgba(#fff,.99);
      border-top: 1px solid rgba(#000,.04);
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
    }  
  }
  &__item:first-child {
    padding-top: 0;
  }
  &__item:last-child {
    padding-bottom: 0;
  }
  // to be used for flexing content on the left with an action (e.g. button) on the right
  &__item-container {
    align-items: center;
    display: flex;
    > span:first-child {
      padding-right: 1rem;
    }
  }
  &__item-title {
    display: block;
    font-weight: $p21-font-weight-semi-bold;
    line-height: 1.2;
  }
  &__item-description {
    display: block;
    color: $text-muted;
    flex: 0 0 auto;
    font-size: $p21-font-size-semi-sm;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  &__item-btn {
    margin-left: auto;
  }
  &__item:hover {
    #{$self}__item-title {
      @include text-underline;
    }
    #{$self}__item-title,
    #{$self}__item-description {
      color: #000;
    } 
    #{$self}__item-btn {
      animation: pulse .25s ease-in-out;
    }
  }
}

.callout-list {
  &__title {
    margin: 0;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    line-height: $line-height-base;
    text-transform: uppercase;
  }

  &__item {
    margin-top: $list-group-item-padding-y;
    padding-bottom: $list-group-item-padding-y;
    border-bottom: $border-width solid $border-color;
  }

  &__item:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
}
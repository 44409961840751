/* =============================================================================
   Tooltip Base
   ========================================================================== */

/// Added to remove shadow from tooltips inside <h1> tags...EX:"Deploy to Production" button
h1 .tooltip {
  text-shadow: none;
}

// IE: Needed for tooltip to show on disabled element
.has-tooltip-inline {
  display: inline-block;
}

.tooltip-inner {
  .htCore & {
    width: $tooltip-max-width - 50;
  }
}
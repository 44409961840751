/* =============================================================================
// Empty State Base
//
// Layout Styling
  ========================================================================== */
/* Welcome/empty state design */
.welcome-message {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: calc(100vh - 44px);
}
 
.empty-state {
    margin-inline: auto;
    margin-block: auto;
    text-align: center;

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: calc(#{$spacer} * 2);
        text-align: center;
    }
    
    // zero out default margins for children of items (we are using spacing for consecutive items)
    &__item > * {
        margin-top: 0;
    }

    &__icon {
        color: $text-muted;
      }

    &__icon--sm {
        width: 25px;
        font-size: 25px;
    }

    &__icon--md {
        width: 50px;
        font-size: 50px;
    }
      
    &__icon--lg {
        width: 100px;
        font-size: 100px;
    }

    &__title {
        margin: .5rem 0 .25rem;
        font-size: 1rem;
        font-weight: $font-weight-normal;
        line-height: $line-height-base;
        color: $text-muted;
    }

    &__title--no-results {
        color: $body-color;
        margin: $headings-margin-bottom auto $headings-margin-bottom;
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        line-height: $headings-line-height;
    }

    &__title--no-results .lead {
        display: block;
        margin: 0;
        font-size: $h2-font-size;
        font-weight: $font-weight-bold;
    }

    &__description {
        font-size: $font-size-base;
        color: $text-muted;
    }

    &__description--lg > *,
    &__description > * {
        margin: 0;
    }

    // for consecutive items in the description, give them top margin for spacing
    &__description--lg > * + *,
    &__description > * + * {
        margin-top: $spacer;
    }

    &__link {
        margin-top: calc(#{$spacer} / 2);
    }

    &__tips-list {
        color: $body-color;
        display: inline-block;
        margin: 0 auto $spacer;
        list-style: disc outside none;
    }

    &__tips-list-item {
        margin-bottom: calc(#{$spacer} / 4);
        text-align: left;
        line-height: $line-height-base;
    }
}

/* =============================================================================
    Horizontal
    ========================================================================== */
.empty-state--horizontal {
  display: flex;
  align-items: flex-start;
  margin-inline: revert;
  margin-block: revert;
  text-align: left;

  .empty-state__img {
    margin-right: calc(#{$spacer} / 2);
  }

  .empty-state__title {
    margin: 0 0 $headings-margin-bottom;
    font-size: 1rem;
    font-weight: $headings-font-weight;
    line-height: $line-height-base;
    color: $text-muted;
  }

  .empty-state__message {
    margin-left: calc(#{$spacer} / 2);
    font-size: $font-size-base;
    color: $text-muted;
  }
}

/* =============================================================================
    Miller Columns
    ========================================================================== */
  .notification-feed .empty-state,
  .miller-columns__column .empty-state {
    padding-top: 5rem;
  }  

/* =============================================================================
// Local variables
========================================================================== */
$p21-sidebar-nav-padding-x: 12px !default;
$p21-sidebar-nav-icon-size: 36px !default;
$p21-sidebar-nav-item-padding-x: 2px !default;
$p21-sidebar-nav-item-padding-y: 4px !default;
$p21-sidebar-nav-contextual-width: 211px !default;
$p21-sidebar-nav-contextual-width-collapsed: 64px !default;
//This variable is also global on the _variable-overrides.scss b/c it's also used in _base.scss
$p21-sidebar-nav-collapse-transition: 500ms cubic-bezier(0.2, 0, 0, 1) 0s;

/* =============================================================================
// Sidebar overlay
//
// Displays under the sidebar nav to focus on menu items
   ========================================================================== */
#sidebar-overlay {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* =============================================================================
// Sidebar
//
// Primary container that houses all global navigation
   ========================================================================== */
#sidebar {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5;
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  display: flex;
  z-index: $p21-sidebar-nav-zindex;

  // Names that appear next to icons when nav is collapsed
  .tooltip-inner {
    white-space: nowrap;
    max-width: 400px;
  }

  // Primary and secondary navigation <nav> menus
  nav {
    width: 64px;
    padding: 24px $p21-sidebar-nav-padding-x 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    transition: width $p21-sidebar-nav-collapse-transition;

    // Lists inside the <nav>
    .menu,
    .submenu {
      display: none;
      padding-left: 0;
      flex-direction: column;
      margin-bottom: 0;
      list-style: none outside none;

      // Title for each navigation list
      .menu-title {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        color: #aeaeae;
        padding: 0 $p21-sidebar-nav-padding-x 4px;
        line-height: 44px;
        white-space: nowrap;
      }

      // Icons that appear when you have a new message in your inbox
      .notifications {
        position: absolute;
        left: 20px;
        top: 4px;

        &.label {
          padding: 0.25em 0.4em;
          border-radius: 4px;
        }
      }
    }

    // Lists found mostly in the global secondary sidebar (messages, help center, profile)
    .submenu {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fafafa;
      padding: 12px 8px;
      border-radius: 3px;
      box-shadow: rgba(9, 30, 66, 0.25) 0 4px 8px -2px,
        rgba(9, 30, 66, 0.31) 0px 0px 1px;

      a,
      button {
        width: auto;
        color: #333;
        min-height: 0;
        height: 28px;
        white-space: nowrap;
      }
    }

    // Selected lists found in the global primary and secondary sidebar
    .menu.active {
      display: flex;
    }

    // Selected lists found in the global secondary sidebar
    .submenu.active {
      display: flex;
      z-index: 2;

      .submenu-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #888;
        padding: 0 7px;
        line-height: 32px;
        white-space: nowrap;
      }
    }

    /* =============================================================================
    // Sidebar animations
    //
    // Animation for contextual menus that have back buttons
     ========================================================================== */
     // Animation menu
    .sidebar-contextual__list {
      display: flex;
      position: absolute;
      left: -110%;
      transition: all .3s ease-in-out;

      &.has-back-btn {
        left: 110%;
      }

      &.active {
        left: $p21-sidebar-nav-padding-x;
      }

      // jq-sidebar for non-react parts of the product
      // reposition contextual menu list
      @at-root #sidebar nav.not-on-validation .sidebar-contextual__list {
        position: relative;
        left: 0;
        display: none;

        &.active {
          display: block;
        }
      }

      @at-root .collapsed .sidebar-contextual__list {
        width: $p21-sidebar-nav-icon-size + ($p21-sidebar-nav-item-padding-y * 2);
      }

      // Position the Validate ul list far to the left when collapsed
      // This stops the user from seeing the menu quickly animate to the left when expanding the collapsed menu
      @at-root #sidebar nav.collapsed.showing-global-menu .sidebar-contextual__list {
        left: -400%;
      }
    }

    // Parent <nav> for the contextual menu. 
    // If a global menu item is clicked it will add the classname to show the current state.
    // This stops the swipe animation from playing when a back button is already showing in the menu.
    &#sidebar-contextual.showing-global-menu .has-back-btn {
      left: $p21-sidebar-nav-padding-x;
      display: none;

    &.active {
      display: flex;
    }
  }

    // Lists found in the global sidebar (messages, help center, profile)
    #sidebar-global-secondary {
      position: relative;
    }

    // Message menu
    #sidebar-contextual-messages.submenu {
      transform: translate3d(48px, -80px, 0px);
    }

    // Help center menu
    #sidebar-contextual-help.submenu {
      transform: translate3d(48px, -140px, 0px);

      button {
        width: 155px;
      }
    }

    // Profile account menu
    #sidebar-contextual-profile.submenu {
      transform: translate3d(48px, -50px, 0px);
    }

    // Active list items in the sidebar
    li.active a,
    li.active button {
      background-color: #5c5c5c;
    }

    // List items in the sidebar
    li {
      padding: $p21-sidebar-nav-item-padding-y 0;
      overflow: hidden;

      img {
        width: 32px;
      }

      a,
      button {
        display: flex;
        align-items: center;
        min-height: $p21-sidebar-nav-icon-size;
        width: 182px;
        color: #fff;
        border-width: 0;
        border-radius: 3px;
        padding: 0 8px;
        cursor: pointer;

        // IE11 compatibility
        // https://stackoverflow.com/questions/19371626/flexbox-not-centering-vertically-in-ie
        &:after {
          min-height: inherit;
          content: "";
        }

        &:not(.disabled):hover {
          background-color: #777;
        }

        &:focus {
          outline: none;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .svg-inline--fa {
        width: 20px;
        height: 20px;
        overflow: visible;
      }

      span:not(.badge) {
        margin-left: 24px;
      }

      hr {
        margin-top: 11px;
        margin-bottom: 11px;
      }
    }

    // Hover state for the sidebar container
    &:hover {
      width: 210px;
    }

    // Collapsed state for the sidebar container
    &.collapsed {
      width: 64px;

      .menu {
        li {

          a,
          button {
            width: $p21-sidebar-nav-icon-size;
          }

          span:not(.badge) {
            display: none;
          }
        }
      }

      // Submenu when the sidebar is in the collapsed state
      .submenu {
        li {
          padding: 0;

          a {
            width: auto;
          }

          button {
            width: 100%;
          }

          a:hover,
          button:hover {
            background-color: #ddd;
          }
        }
      }
    }

    hr {
      border-color: #666;
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .sidebar-back {
      button {
        color: #fff;
        display: flex;
        align-content: center;
        padding: 8px $p21-sidebar-nav-padding-x;
        margin-top: 3px;
        border: 0;
        background: transparent;
        border-radius: 3px;
        width: 100%;

        &:hover {
          background-color: #777;
        }

        .svg-inline--fa {
          width: 20px;
          height: 20px;
          font-size: 14px;
        }

        span:not(.badge) {
          margin-left: 24px;
        }
      }

      hr {
        margin-top: 10px;
        margin-bottom: 4px;
      }
    }
  }

  // Primary and secondary navigation <nav> menus
  nav#sidebar-global {
    background-color: #222;

    li {
      padding: $p21-sidebar-nav-item-padding-y $p21-sidebar-nav-item-padding-x;
    }

    li.active button {
      background-color: #5c5c5c;
    }

    button {
      background-color: #222;
    }

    .submenu button {
      background-color: #fafafa;
    }

    button:hover,
    li.active button:hover {
      background-color: #777;
    }

    .submenu button:hover {
      background-color: #ddd;
    }

    #sidebar-global-primary {
      li:first-child {
        padding: 0;
      }
    }

    // Home button link
    #sidebar-global-home {
      height: 40px;
      width: 186px;
      padding: 4px;
      margin-bottom: 8px;

      span {
        margin-left: 16px;
      }
    }

    &.collapsed #sidebar-global-home {
      width: 40px;
    }

    #sidebar-global-profile {
      padding: $p21-sidebar-nav-item-padding-y 6px;

      img {
        height: 24px;
        width: 24px;
        border-radius: 50%;
      }

      span {
        margin-left: 22px;
      }
    }
  }

  // 2nd column navigation <nav> menus
  nav#sidebar-contextual {
    width: $p21-sidebar-nav-contextual-width;
    background-color: #333;
    overflow-y: auto;
    overflow-x: hidden;

    &.hidden {
      width: 0;
      padding: 0;
    }

    &:hover {
      width: $p21-sidebar-nav-contextual-width;
    }

    &.collapsed {
      width: $p21-sidebar-nav-contextual-width-collapsed;

      li {
        padding: $p21-sidebar-nav-item-padding-y $p21-sidebar-nav-item-padding-x;

        a {
          width: $p21-sidebar-nav-icon-size;
          height: $p21-sidebar-nav-icon-size;
          padding: 0 8px;
        }
      }

      .menu-title {
        visibility: hidden;
        overflow: hidden;
      }
    }

    li {
      a {
        width: 187px;
        padding: 0 $p21-sidebar-nav-padding-x;
      }
    }
  }

  &-toggle {
    button {
      position: relative;
      top: 32px;
      color: #333;
      opacity: 0;
      height: 24px;
      width: 24px;
      transform: translate(-50%);
      border-width: 0;
      border-radius: 50%;
      padding: 0 2px 0 0;
      box-shadow: rgba(9, 30, 66, 0.08) 0 0 0 1px,
        rgba(9, 30, 66, 0.08) 0 2px 4px 1px;
      transition: background-color 100ms linear 0s, color 100ms linear 0s,
        opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
        transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

      svg {
        height: 16px;
        pointer-events: none;
        vertical-align: text-top;
      }

      &:hover {
        background-color: theme-color("p21");
        color: #fff;
        opacity: 1;
      }

      &.active {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    }

    &.collapsed {
      button {
        opacity: 1;
        transform: translate3d(-44px, 0px, 0px) rotate(180deg);
      }
    }
  }
}

/* =============================================================================
// Navigation modal
//
// Used in the about P21 and Third Party Notices
========================================================================== */
#sidebar-about-modal {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5;
  overflow: hidden;

  .modal-content {
    border: 0;
    border-radius: 6px;
    width: 100%;
  }

  &.modal-dialog {
    width: 600px;
    max-width: 600px;
  }

  .modal-dialog {
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);

    &::before {
      display: block;
      height: calc(100vh - 3.5rem);
      content: '';
    }
  }

  .modal-header {
    background: none #2d2d2d;
    height: 200px;
    line-height: 170px;
    text-align: center;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 8px 15px 5px;
    border-radius: 6px 6px 0 0;
    display: block;
    align-items: unset;
    justify-content: unset;

    .close {
      font-size: 24px;
      color: #ccc;
      opacity: 1;
      text-shadow: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      line-height: 1;
      font-weight: bold;
      float: right;
    }
  }

  .modal-body {
    padding: 40px;
    font-size: 16px;
    text-align: center;

    p {
      margin-bottom: 10px;
    }

    .title {
      font-size: 26px;
    }

    .copyright {
      color: #888;
    }

    .version {
      display: flex;
      justify-content: center;

      &-label,
      &-number a {
        margin-right: 5px;
      }
    }
  }
}
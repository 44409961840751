/* =============================================================================
// Error Pages Base
//
// Layout Styling
  ========================================================================== */
.error-page {
    &-header {
        padding-top: 20px;
        background: $body-bg;
        text-align: center;
    }

    &-body {
        height: calc(100vh - 102px);
        margin: 0 !important;
        padding: 75px 0 20px 0 !important;
        text-align: center;
        overflow-y: auto;
    }

    @media (max-width: 767px) {
        &-header {
            margin-top: -18px;
        }

        &-body {
            height: auto;
            padding: 40px 20px !important;
        }
    }
}

.error-title {
    margin-bottom: 0.5rem;
    color: $gray-700;
    font-size: 28px;
    font-weight: normal;
    text-shadow: 0 1px 0 $white;
}

.error-description {
    display: inline-block;
    max-width: 500px;
    margin: 0 auto;
    color: $gray-500; 

    &--important {
        color: $gray-900;
    }
}

.error-ctas {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 1rem;
}

.error-img {
    width: 100%;
    height: auto;

    &--400 {
        max-width: 700px;
    }
    
    &--403-access {
        max-width: 574px;
    }
    
    &--403-hidden {
        max-width: 744px;
    }
    
    &--404 {
        max-width: 550px;
    }
    
    &--500 {
        max-width: 586px;
    }
}
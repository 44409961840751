/* =============================================================================
   Color system
   ========================================================================== */
$white:    #fff;
$gray-100: #fafafa;
$gray-200: #eeeeee;
$gray-300: #dbdbdb;
$gray-400: #cdcdcd;
$gray-500: #999999;
$gray-600: #666666;
$gray-700: #555555;
$gray-800: #333333;
$gray-900: #222222;
$black:    #000;

$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue: #2d98da;
$red: #e74c3c;
$orange: #f58220;
$yellow: #f39c12;
$green: #20bf6b;
$teal: #2cb0bc;

$colors: (
  "blue": $blue,
  "red": $red,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "sunflower": #ffc312,
  "poppy": #fb7c4e,
  "watermelon": #fc5664,
  "raspberry": #fc72b9,
  "lavender": #ea6ae8,
  "violet": #9b61fa,
  "blurple": #6e86f7,
  "sky": #45cdeb,
  "peppermint": #43ddb2,
  "spearmint": #62d17a
  );

$primary: $teal;
$primary-100: #e9f7f8;
$primary-200: #defcfe;
$primary-300: #abdfe4;
$primary-500: $teal;
$primary-700: #228993;
$primary-900: #0B2C2F;  

$secondary: $gray-600;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $gray-800;

$theme-colors: (
  "p21": $orange,
  "open": #4b6584,
  "fix-now": #2d98da,
  "fix-later": #0abde3,
  "to-close": #18d7ba,
  "closed": #20bf6b,
  "fixed": #188D4F,
  "reject": #b94a48,
  "high": $red,
  "error": $red,
  "medium": $yellow,
  "low": #b4b59a,
  "notice": #b4b59a,
  "source": $gray-500,
  "neutral": $gray-500,
  "default": $gray-200,
);


/* =============================================================================
   Options
   ========================================================================== */
$enable-caret: false;


/* =============================================================================
   Body
   ========================================================================== */
// Settings for the `<body>` element.
$body-bg: $gray-200;
$p21-body-bg-dark: #444;

/* =============================================================================
   Links
   ========================================================================== */
$link-color: darken($primary, 6.5%);
$link-hover-decoration: none;


/* =============================================================================
   Components
   ========================================================================== */
$p21-border-width-in-rem: 0.06rem;

$border-width: 1px;
$border-color: $gray-400;
$border-radius: .25rem;
$box-shadow-sm: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
$box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$box-shadow-lg: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

$caret-width: 0.875em;
$caret-vertical-align: -0.15em;
$caret-spacing: 0.5rem;


/* =============================================================================
   Typography
   ========================================================================== */
$font-size-base: .875rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: 0.65625rem; //0.6875rem;
$p21-font-size-semi-sm: 0.75rem;

$p21-font-weight-semi-bold: 500 !default;

$h1-font-size: 1.75rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$headings-line-height: 1.35;
$headings-color: $gray-800;

$text-muted: $gray-500;


/* =============================================================================
   Grid breakpoints
   ========================================================================== */
$grid-breakpoints: (
        xs: 0,
        sm: 1200px,
        md: 1500px,
        lg: 1900px,
        xl: 2300px
);

// Grid containers
$container-max-widths: (
        sm: 1140px,
        md: 1440px,
        lg: 1840px,
        xl: 2240px
);


/* =============================================================================
   Grid
   ========================================================================== */
$grid-gutter-width: 30px;

// Custom content spacing
$p21-content-spacing-y: $grid-gutter-width / 3;
$p21-content-spacing-x: $grid-gutter-width / 2;


/* =============================================================================
   Buttons + Forms
   Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
   ========================================================================== */
$input-btn-padding-y-sm: 0.1875rem;
$input-btn-padding-x-sm: .5rem;
$input-btn-font-size-sm: $font-size-sm;


/* =============================================================================
   Buttons
   ========================================================================== */
$btn-disabled-opacity: .4;
$input-btn-focus-color: rgba($primary, .4);
$input-btn-border-width: $border-width;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

// Custom variables
$p21-not-disabled: ':not(:disabled):not(.disabled):not([disabled])';
$p21-btn-text-shadow: 0 -1px 0 rgba($black, 0.25) !default;
$p21-btn-border-color: rgba($black, 0.2) rgba($black, 0.2) rgba($black, 0.3) !default;
$p21-btn-border-color-datatable: $gray-500;
// Filters
$p21-filters-margin-x: 1rem;
$p21-filters-margin-y: 0.25rem;
$p21-filters-border-color: $gray-500;
// Button icons
$p21-btn-icon-size: 2.25rem;
$p21-btn-icon-size-sm: 1.875rem;
$p21-btn-icon-font-size: 1rem;
$p21-btn-icon-font-color: $dark;

/* =============================================================================
   Forms
   ========================================================================== */
$p21-form-group-spacing-y: 1.25rem !default; // 20px
$p21-form-section-spacing-y: 2rem !default;
$p21-input-width-lg: 100% !default;
$p21-input-width-md: 77% !default;
$p21-input-width-sm: 40% !default;
$p21-input-width-xs: 3rem !default;

$input-disabled-bg: $gray-200;
$input-border-width: $input-btn-border-width;
$input-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
$input-focus-border-color: #34495e;
$input-focus-box-shadow: 0 0 8px rgba(52, 73, 94, 0.3);

$input-placeholder-color: $gray-500;

// Custom inputs
$form-group-margin-bottom: 0;
$p21-form-group-border-top-color: #fff;
$p21-form-group-border-bottom-color: #eee;

$custom-select-indicator-color: $gray-600;
$custom-checkbox-indicator-border-radius: 0.15rem;
$custom-control-indicator-size: 1.12rem; 
$custom-control-indicator-bg-size: 60% 60%;

$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-inline-checked-bg: $primary-300;
$custom-control-indicator-checked-disabled-bg:  #ccc;
$custom-control-indicator-checked-border-color: $primary;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;

$custom-control-label-checked-bg-color: $primary-100;
$custom-control-label-checked-border-color: $primary;

// Custom svgs for urls
// Need to replace spaces in url with %20 for url encoding
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2014%2010'><path%20fill='#{$custom-control-indicator-checked-color}'%20d='m11.037.872-5.71 5.562-2.361-2.33a1.318 1.318 0 0 0-1.836 0 1.249 1.249 0 0 0 0 1.793l3.303 3.231a1.316 1.316 0 0 0 1.833 0l6.604-6.462a1.248 1.248 0 0 0 0-1.794 1.315 1.315 0 0 0-1.833 0Z'/></svg>");

$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg%20xmlns='http://www.w3.org/2000/svg'%20width='4'%20height='4'%20viewBox='0%200%204%204'><path%20stroke='#{$custom-checkbox-indicator-indeterminate-color}'%20d='M0%202h4'/></svg>");

$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg%20xmlns='http://www.w3.org/2000/svg'%20width='12'%20height='12'%20viewBox='-4%20-4%208%208'><circle%20r='3'%20fill='#{$custom-control-indicator-checked-color}'/></svg>");

$custom-checkbox-indicator-icon-checked-color: url("data:image/svg+xml,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%2014%2010'><path%20fill='#{$primary}'%20d='m11.037.872-5.71 5.562-2.361-2.33a1.318 1.318 0 0 0-1.836 0 1.249 1.249 0 0 0 0 1.793l3.303 3.231a1.316 1.316 0 0 0 1.833 0l6.604-6.462a1.248 1.248 0 0 0 0-1.794 1.315 1.315 0 0 0-1.833 0Z'/></svg>");


/* =============================================================================
   Toggle Switch
   ========================================================================== */
$p21-toggle-width: 3.75rem !default;
$p21-toggle-height: $p21-toggle-width / 2 !default;
$p21-toggle-knob-size: $p21-toggle-height - (0.06rem * 2);
$p21-toggle-knob-size-hover: $p21-toggle-knob-size * 1.5;
$p21-toggle-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55) !default;
$p21-toggle-position-top: calc(((#{$p21-toggle-height} - #{$p21-toggle-knob-size}) / 2) - #{$input-border-width}) !default;
$p21-toggle-position-left-off: $input-border-width !default;
$p21-toggle-position-left-on: calc(100% - #{$p21-toggle-knob-size}) !default;

$p21-check-hover-opacity-on: 0.3 !default;
$p21-check-hover-opacity-off: 0 !default;


/* =============================================================================
   Z-Index
  ========================================================================== */
$p21-zindex-select: 1055 !default;


/* =============================================================================
   Navs
  ========================================================================== */
$p21-sidebar-nav-collapse-transition: 500ms cubic-bezier(0.2, 0, 0, 1) 0s !default;
$p21-sidebar-nav-zindex: 200;

$nav-link-padding-y: 0.188rem;
$nav-link-padding-x: 0.5rem;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: #aaa;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-900;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $nav-tabs-border-color;

$nav-divider-color: $nav-tabs-border-color;
$nav-divider-margin-y: 0.5rem ;

$p21-sidebar-global-width: 64px;
$p21-sidebar-contextual-width-collapse: 64px;
$p21-sidebar-contextual-width-default: 211px;


/* =============================================================================
   Dropdowns
   ========================================================================== */
// Dropdown menu container and contents.
//$dropdown-font-size: 0.75rem;
$dropdown-link-hover-bg: $primary-200;
$dropdown-link-active-bg: $primary-100;
$dropdown-link-active-color: $primary-900;
$dropdown-link-active-border: $primary;
$dropdown-link-disabled-color: $gray-500;

$dropdown-item-padding-y: .25rem;
$dropdown-item-padding-x: .75rem;

// Custom variables for select choices in dropdowns
$p21-select-choice-border-color: #aaa !default;
$p21-select-choice-color: #333 !default;
$p21-select-choice-box-shadow: 0 0 2px $white inset, 0 1px 0 rgba($black, 0.05) !default;
$p21-select-choice-arrow-size: 0.75rem !default;


/* =============================================================================
   Cards
   ========================================================================== */
$card-spacer-y: 1rem;
$card-spacer-x: 1rem;
$card-border-width: $border-width;
$card-border-color: $border-color;
$card-border-radius: $border-radius;
$card-cap-bg: $white;
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: $white;


/* =============================================================================
   Tooltips
   ========================================================================== */
$tooltip-font-size: 0.75rem;
$tooltip-opacity: 1;


/* =============================================================================
   Popovers
   ========================================================================== */
$popover-font-size: $font-size-base;
$popover-max-width: 800px;
$popover-body-padding-y: 0.75rem;


/* =============================================================================
   Modals
   ========================================================================== */
// Padding applied to the modal body
$modal-inner-padding: 1rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: .5rem;
$modal-title-line-height: 1.1;
$modal-header-border-color: #cdcdcd;
$modal-footer-border-width: 0;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xl: 1200px;
$modal-lg: 800px;
$modal-md: 500px;
$modal-sm: 350px;

$modal-slide-in-transition: transform 0.4s ease-out;

//Sidebar modal variables
$p21-modal-sidebar-inner-padding: $modal-inner-padding;
$p21-modal-sidebar-top-spacing: 20px;
$p21-modal-sidebar-arrow-size: 8px;
$p21-modal-notification-header-height: 150px;

/* =============================================================================
   Alerts
   ========================================================================== */
$alert-padding-y: 0.625rem;
$alert-padding-x: 1rem;
$alert-margin-bottom: 0;
$alert-bg-level: -11;
$alert-border-level: -2;
$alert-color-level: 7;


/* =============================================================================
   Progress bars
   ========================================================================== */
$progress-height: 6px;
$progress-font-size: $font-size-base * .75;
$progress-bg: $gray-200;
$progress-border-radius: 50rem;
$progress-box-shadow: inset 0 .1rem .1rem rgba($black, .1);
$progress-bar-color: $white;
$progress-bar-bg: $primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width .6s ease;


/* =============================================================================
   List Group
   ========================================================================== */
$list-group-item-padding-y: 0.5rem;
$list-group-item-padding-x: 1rem;

/* =============================================================================
   Breadcrumbs
   ========================================================================== */
$breadcrumb-font-size: 0.7rem;
$breadcrumb-padding-y: 0.625rem;
$breadcrumb-padding-x: 1.25rem;
$breadcrumb-item-padding: 0.7rem;

$breadcrumb-margin-bottom: 0;

$breadcrumb-bg: #eaeaea;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-800;
$breadcrumb-divider: url(../../images/breadcrumb.png);

$breadcrumb-border-radius: 0;


/* =============================================================================
   Carousel
   ========================================================================== */
$carousel-control-color: $gray-900;
$carousel-control-width: 1rem;
$carousel-control-opacity: .6;
$carousel-control-hover-opacity: 1;
$carousel-control-transition: opacity .15s ease;

$carousel-indicator-width: 6px;
$carousel-indicator-height: 6px;
$carousel-indicator-hit-area-height: 6px;
$carousel-indicator-spacer: 6px;
$carousel-indicator-active-bg: $gray-900;
$carousel-indicator-transition: opacity .6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $gray-900;

$carousel-control-icon-width: 20px;

$carousel-transition-duration: .4s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg%20xmlns='http://www.w3.org/2000/svg'%20fill='#{$carousel-control-color}'%20viewBox='0%200%208%208'><path%20d='M5.25%200l-4%204%204%204%201.5-1.5L4.25%204l2.5-2.5L5.25%200z'/></svg>");
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg%20xmlns='http://www.w3.org/2000/svg'%20fill='#{$carousel-control-color}'%20viewBox='0%200%208%208'><path%20d='M2.75%200l-1.5%201.5L3.75%204l-2.5%202.5L2.75%208l4-4-4-4z'/></svg>");


/* =============================================================================
   Widget
   This is a custom React component for P21
   ========================================================================== */
$p21-widget-spacer-y: 0.75rem !default;
$p21-widget-spacer-x: 1rem !default;

$p21-widget-background-color: #fafafa !default;
$p21-widget-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !default;
$p21-widget-border-size: 1px !default;
$p21-widget-border-style: solid !default;
$p21-widget-border-color: #cdcdcd !default;

$p21-widget-header-gradient: linear-gradient(to bottom, #fafafa 0%, #f2f2f2 100%) !default;
$p21-widget-header-box-shadow: 0 1px 0 #fff inset !default;
$p21-widget-title-padding-y: 0.5rem !default;
$p21-widget-title-padding-x: 0.625rem !default;
$p21-widget-title-size: 0.75rem !default;
$p21-widget-title-line-height: 1.3 !default;

$p21-widget-link-hover-decoration: underline;

$p21-widget-stat-image-height: 26px;


/* =============================================================================
   Tables
   These are custom P21 variables
   ========================================================================== */
$p21-table-sub-head-color: #4a4a4a;
$p21-table-row-bg-hover: $dropdown-link-hover-bg;
$p21-table-row-bg-selected: $dropdown-link-active-bg;
$p21-table-row-bg-selected-color: $dropdown-link-active-color;
$p21-table-spacer-x: 0.625rem;
$p21-table-spacer-y: 0.2rem;

$p21-cell-bg-success: lighten($success, 42%);
$p21-cell-bg-warning: lighten($warning, 42%);
$p21-cell-bg-danger: lighten($danger, 38%);
$p21-cell-bg-info: lighten($info, 42%);

$p21-notification-border-width: 5px;


/* =============================================================================
   Handson Tables
   These are custom P21 variables
   ========================================================================== */
$p21-ht-cell-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.8);
$p21-ht-cell-padding-x: 4px;
$p21-ht-cell-padding-y: 0;
$p21-ht-cell-dropdown-icon-size: 0.75rem;
$p21-ht-cell-disabled-bg: $input-disabled-bg;


/* =============================================================================
   Skeleton Loaders
   ========================================================================== */
$p21-skeleton-element-color: rgba(0, 0, 0, 0.17) !default;
$p21-skeleton-loading-animation-time: 2s !default;
$p21-skeleton-margin-standard: 16px !default;
$p21-skeleton-margin-small: $p21-skeleton-margin-standard / 2 !default;
$p21-skeleton-circle-size: 50px !default;
$p21-skeleton-hr-height: 2px !default;
$p21-skeleton-line-border-radius: 2px !default;
$p21-skeleton-line-height: 0.5rem !default;
$p21-skeleton-line-margin-bottom: 8px !default;
$p21-skeleton-headline-height: $p21-skeleton-line-height * 2 !default;
$p21-skeleton-square-height: 150px !default;
$p21-skeleton-card-border-radius: 0 !default;
$p21-skeleton-card-background-color: $p21-widget-background-color !default;
$p21-skeleton-card-box-shadow: $p21-widget-box-shadow !default;


/* =============================================================================
   Circle with Checkmark Loader
   ========================================================================== */
$p21-circle-loader-border-width: 2px !default;
$p21-circle-loader-width: 4.5em !default;
$p21-circle-loader-height: $p21-circle-loader-width !default;
$p21-circle-loader-check-width: 1em !default;
$p21-circle-loader-check-height: $p21-circle-loader-check-width * 2 !default;
$p21-circle-loader-x-width: 2em !default;
$p21-circle-loader-x-height: $p21-circle-loader-x-width !default;


/* =============================================================================
   Images
   ========================================================================== */
$p21-avatar-size-comment: 36px;
$p21-avatar-size-sm: 30px;
$p21-avatar-size-md: 50px;
$p21-avatar-size-lg: 75px;

$p21-hex-width-md: 57px;
$p21-hex-height-md: 50px;

/* =============================================================================
   Grab Attention
   ========================================================================== */
.get-attention {
    &--jump {
        animation: jump 1s forwards cubic-bezier(.84, -0.54, .31, 1.19);
    }

    &--jump-shake {
        transform-origin: bottom;
        animation: jump-shake 1.3s forwards cubic-bezier(0.51, 0, 0.44, 1)
    }

    &--waggle {
        animation: waggle 1s forwards ease-out;
    }

    &--spin {
        animation: spin 1.5s forwards cubic-bezier(.56, -0.35, .22, 1.5);
    }
}

// Keyframes
@keyframes jump {
    0% {
        transform: none;
    }

    50% {
        transform: translateY(-75%);
    }
}

@keyframes jump-shake {
    0% {
        transform: translate(0, 0) scale(1, 1);
    }

    30% {
        transform: translate(0, 0) scale(1.25, 0.75);
    }

    50% {
        transform: translate(0, -70%) scale(1, 1);
    }

    55% {
        transform: translate(0, -70%) rotate(15deg);
    }

    60% {
        transform: translate(0, -70%) rotate(-15deg);
    }

    65% {
        transform: translate(0, -70%) rotate(15deg);
    }

    70% {
        transform: translate(0, -70%) rotate(-15deg);
    }

    90% {
        transform: translate(0, 0) scale(1.25, 0.75);
    }

    100% {
        transform: translate(0, 0) scale(1, 1);
    }
}

@keyframes waggle {
    0% {
        transform: none;
    }

    50% {
        transform: rotateZ(-20deg) scale(1.2);
    }

    60% {
        transform: rotateZ(25deg) scale(1.2);
    }

    67.5% {
        transform: rotateZ(-15deg) scale(1.2);
    }

    75% {
        transform: rotateZ(15deg) scale(1.2);
    }

    82.5% {
        transform: rotateZ(-12deg) scale(1.2);
    }

    85% {
        transform: rotateZ(0) scale(1.2);
    }

    100% {
        transform: rotateZ(0) scale(1);
    }
}

@keyframes spin {
    50% {
        transform: rotateZ(-20deg);
        animation-timing-function: ease;
    }

    100% {
        transform: rotateZ(360deg);
    }
}


/* =============================================================================
   Ring
   ========================================================================== */
.ring-bell,
.contains-bell:hover .icon-bell,
.contains-bell:hover .badge {
    transform-origin: center top;
    animation: ring 1s ease-out;
}

.contains-bell:hover .badge {
    animation-delay: 0.075s;
}

@keyframes ring {
    0% {
        transform: rotate(35deg);
    }

    12.5% {
        transform: rotate(-30deg);
    }

    25% {
        transform: rotate(25deg);
    }

    37.5% {
        transform: rotate(-20deg);
    }

    50% {
        transform: rotate(15deg);
    }

    62.5% {
        transform: rotate(-10deg)
    }

    75% {
        transform: rotate(5deg)
    }

    100% {
        transform: rotate(0);
    }
}



/* =============================================================================
   Pulse
   ========================================================================== */
.pulse {
    animation-name: pulse;
}

@keyframes pulse {
    0% {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}


// Border pulsing
.is-pulsing-border {
    animation: border-pulse 2s cubic-bezier(0, 0, 0.2, 1) infinite;
    border-color: $primary !important;
}

.is-pulsing-border-5x {
    animation: border-pulse 2s cubic-bezier(0, 0, 0.2, 1) 5;
    border-color: $primary !important;
    position: relative;
    z-index: 1;
}

@keyframes border-pulse {
    0% {
        box-shadow: 0 0 0 -1px rgba($primary, 0.75);
    }
    75% {
        box-shadow: 0 0 0 16px rgba($primary, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba($primary, 0);
    }
}


/* =============================================================================
   Fades
   ========================================================================== */
.fade-in {
    animation: fadeIn 0.85s forwards cubic-bezier(0.81, 0, 1, 1);

    &--left {
        animation: fadeInLeft 0.5s forwards ease-in;
    }

    &--right {
        animation: fadeInRight 0.9s forwards ease-in;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        animation-timing-function: ease;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(75px);
        animation-timing-function: ease;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-125px);
        animation-timing-function: ease;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


/* =============================================================================
   Delays
   ========================================================================== */
$start-ms: 100;

@while $start-ms < 1501 {
    .delay-by-#{$start-ms}ms {
        animation-delay: #{$start-ms}ms;
    }

    $start-ms: $start-ms + 100;
}


/* =============================================================================
    Loops
    ========================================================================== */
$replay: 2;

@while $replay < 10 {
    .play-#{$replay}x {
        animation-iteration-count: #{$replay};
    }

    $replay: $replay + 1;
}
/* =============================================================================
   Skeleton Screen Loaders
   ========================================================================== */
/* Animation */
@keyframes ssc-loading {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

/* Animation partial */
%ssc-animation {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    animation: ssc-loading $p21-skeleton-loading-animation-time infinite;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.3), transparent);
  }
}

/** Main class */
.ssc {
  cursor: progress;
  user-select: none;
}

/* Figures, Objects */
.ssc-circle {
  display: block;
  background-color: $p21-skeleton-element-color;
  border-radius: 50%;
  width: $p21-skeleton-circle-size;
  height: $p21-skeleton-circle-size;

  @extend %ssc-animation;
}

.ssc-hr {
  display: block;
  width: 100%;
  background-color: $p21-skeleton-element-color;
  height: $p21-skeleton-hr-height;
}

.ssc-line {
  display: block;
  background-color: $p21-skeleton-element-color;
  border-radius: $p21-skeleton-line-border-radius;
  width: 100%;
  height: $p21-skeleton-line-height;

  @extend %ssc-animation;
}

.ssc-head-line {
  display: block;
  background-color: $p21-skeleton-element-color;
  border-radius: $p21-skeleton-line-border-radius;
  width: 100%;
  height: $p21-skeleton-headline-height;

  @extend %ssc-animation;
}

.ssc-square {
  display: block;
  background-color: $p21-skeleton-element-color;
  width: 100%;
  height: $p21-skeleton-square-height;

  @extend %ssc-animation;
}

.ssc-chart {
  display: block;
  background-color: $p21-skeleton-element-color;
  width: 100%;
  height: $p21-skeleton-square-height * 2;

  @extend %ssc-animation;
}

.ssc-toolbar {
  display: block;
  background-color: $p21-skeleton-element-color;
  border-radius: $p21-skeleton-line-border-radius * 2;
  width: 100%;
  height: $p21-skeleton-headline-height * 2.5;

  @extend %ssc-animation;
}

// used in conjuction with the .js-skeleton loader class in base.scala.html. provides 'canvas' in which loaders can be placed, using javscript. hidden by default.
.ssc-skeleton-loader {
    --vh-offset: 170px; //approx height of ContentHeader with one line of explainer text and filter controls. magic numbers are not ideal but we can't flex the wrapper/content divs because of datatables mis-calculating heights when parent container has any height values.
    cursor: wait;
    height: calc(99vh - var(--vh-offset));
    position: relative;
}

// optional class that can be passed to loaderSkeletonTable.scala.html. makes loader fill the parent (.ssc-skeleton-loader/.js-skeleton-loader)
.ssc-table-container {
  display: flex;
  flex-direction: column;
  inset: 0;
  position: absolute;
}

// table skeleton that fills sc-table-container, sitting under header and above footer elements
.ssc-table {
  background: #fff;
  display: grid;
  grid-gap: .625rem;
  grid-template-columns: repeat(auto-fit, minmax(50px,1fr));
  flex: 1;
  padding: .625rem;
}

// add scroll bar track visually as the table will nearly always have scrolling
.ssc-table-container .ssc-table {
  overflow-y: scroll;
}

// slight fade in for loaded element
.skeleton-fadeIn {
  animation-name: fadeIn;
  animation-duration: .25s;
  animation-timing-function: ease-out;
}

// slight fade out for skeleton loader. used on .ssc-skeleton-loader/.js-skeleton-loader
.skeleton-fadeOut {
  animation-name: fadeOut;
  animation-duration: .55s;
  animation-timing-function: ease-in;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

/** Wrapper class */
.ssc-wrapper {
    padding: $p21-skeleton-margin-standard;
}

/* Figures, Objects */
.ssc-card {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: $p21-skeleton-card-border-radius;
    box-shadow: $p21-skeleton-card-box-shadow;
    background-color: $p21-skeleton-card-background-color;
}

/* =============================================================================
   Hexagon spinner
   ========================================================================== */
.loader-container--center {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  margin: auto;
  width: 140px;
  height: 140px;
  overflow: visible;
  text-align: center;

  &.offset-sidebar {
    left: 300px
  }
}

.loader-container--absolute {
  position: absolute;
}

.loader-hexagon {
  margin: 0 auto;
}

// Spin the bar
.loader-hexagon__color {
  transform-origin: center center;
  animation: rotate-clockwise 0.8s linear infinite;
}

@keyframes rotate-clockwise {
  to {
    transform: rotate(360deg);
  }
}

/* =============================================================================
// Circle Loader with Checkmark
//
// Found in modal/gritter popup to set the time zone your first time 
   ========================================================================== */
// Credit https://codepen.io/alimuzzaman/pen/LyoyPj

.circle-loader {
  margin: $spacer auto;
  border: $p21-circle-loader-border-width solid rgba(0, 0, 0, 0.2);
  border-left-color: $success;
  animation: loader-spin 1s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  box-sizing: content-box;
}

.circle-loader,
.circle-loader:after {
  display: block;
  border-radius: 50%;
  width: $p21-circle-loader-width;
  height: $p21-circle-loader-height;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $success;
  transition: border 500ms ease-out;
}

.load-error {
  -webkit-animation: none;
  animation: none;
  border-color: $danger;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
  box-sizing: content-box;
}

.checkmark.draw:after {
  opacity: 1;
  height: $p21-circle-loader-check-height;
  width: $p21-circle-loader-check-width;
  transform-origin: left top;
  border-right: $p21-circle-loader-border-width solid $success;
  border-top: $p21-circle-loader-border-width solid $success;
  content: '';
  left: $p21-circle-loader-check-width + 0.25em;
  top: $p21-circle-loader-check-height + 0.25em;
  position: absolute;
  animation-duration: 1.2s;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}

.checkmark.error {
  position: absolute;
  left: $p21-circle-loader-x-width + 0.25em;
  top: ($p21-circle-loader-x-height / 2) + 0.25em ;
  width: $p21-circle-loader-x-width;
  height: $p21-circle-loader-x-height;
}

.checkmark.error:before,
.checkmark.error:after {
  position: absolute;
  content: ' ';
  height: $p21-circle-loader-x-height;
  width: $p21-circle-loader-border-width;
  background-color: $danger;
}

.checkmark.error:before {
  transform: rotate(45deg);
}

.checkmark.error:after {
  transform: rotate(-45deg);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $p21-circle-loader-check-width;
    opacity: 1;
  }
  40% {
    height: $p21-circle-loader-check-height;
    width: $p21-circle-loader-check-width;
    opacity: 1;
  }
  100% {
    height: $p21-circle-loader-check-height;
    width: $p21-circle-loader-check-width;
    opacity: 1;
  }
}
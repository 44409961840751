/* =============================================================================
   Global Base
   ========================================================================== */
body {
  background-color: $p21-body-bg-dark;
}

#wrapper {
  min-height: 100vh;
  background-color: $body-bg;
}

/* =============================================================================
   Icon Base
   ========================================================================== */
.svg-inline--fa.fa-xs,
.icon--xs {
  font-size: 0.75rem;
}

.svg-inline--fa.fa-sm,
.icon--sm {
  font-size: 0.875rem;
}

// add exception for React Datables Tools buttonset icon sizes to match non-React
.dt-button .svg-inline--fa.fa-sm {
  font-size: inherit;
  vertical-align: -0.1em;
  height: 1em;
  width: 1em;
}

.svg-inline--fa,
.icon--md {
  font-size: 1rem;
}

.svg-inline--fa.fa-lg,
.icon--lg {
  font-size: 1.33rem;
}

.icon--xl {
  font-size: 2rem;
}

.avatar,
.avatar-img {
  border-radius: 50%;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: auto;

  &--sm {
    width: $p21-avatar-size-sm;
    height: $p21-avatar-size-sm;
  }

  &--md {
    width: $p21-avatar-size-md;
    height: $p21-avatar-size-md;
  }

  &--lg {
    width: $p21-avatar-size-lg;
    height: $p21-avatar-size-lg;
  }

  &--comment {
    width: $p21-avatar-size-comment;
    height: $p21-avatar-size-comment;
  }
}


// Hexagon Icons
.icon-hex {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $p21-hex-width-md;
  height: $p21-hex-height-md;
  color: $primary;
  text-align: center;

  &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: url("/assets/images/icons/hexagon/hex-50px-primary.svg");
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.2;
  }

  & .icon {
      font-size: $p21-hex-height-md / 2;
  }

  &__text {
      display: block;
      width: 100%;
      font-size: 22px;
      line-height: 1;
  }

  &__text--sm {
      font-size: 10px;
  }

  // Color variations
  .is-disabled--with-cta & {
      color: $gray-600;
  }

  .is-disabled--with-cta &::after {
      background-image: url("/assets/images/icons/hexagon/hex-50px-black.svg");
      opacity: 0.12;
  }

  &--light {
      color: $text-muted;

      &::after {
          background-image: url("/assets/images/icons/hexagon/hex-50px-light-border.svg");
          opacity: 1;
      }
  }
}


/* =============================================================================
   Navigation Base
   ========================================================================== */
#messagesGlobalItem {
  overflow: visible;
}


/* =============================================================================
   Close Base
   ========================================================================== */
.close {
  &:focus-visible, .focus-visible {
      outline: 0;
      border-radius: $btn-border-radius;
      box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($primary), $primary, 15%), .75);
    }

  &:focus {
    outline: 0;
  }
}

button.close {
    padding: 0 0.25rem;
}


/* =============================================================================
   Layout Base: Global Page Structure
   ========================================================================== */

#content {
  min-width: 500px;
  margin-left: $p21-sidebar-global-width + $p21-sidebar-contextual-width-default;
  padding-bottom:  $p21-content-spacing-y;
  background-color: $body-bg;
  transition: margin-left $p21-sidebar-nav-collapse-transition;

  &.full-screen {
      margin-left: $p21-sidebar-global-width;
  }

  &.expanded {
      margin-left: $p21-sidebar-global-width + $p21-sidebar-contextual-width-collapse;
  }
}

// Container for the title and top actions
.content-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $p21-content-spacing-y $p21-content-spacing-x;
    background-image: linear-gradient(to bottom, $white 0%, $body-bg 100%);

    &__title-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__title, h1 {
        margin-right: $headings-margin-bottom / 2;
        margin-bottom: $headings-margin-bottom / 2;
        color: $gray-700;
        font-size: $h1-font-size;
        font-weight: normal;
        text-shadow: 0 1px 0 $white;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__details {
        display: flex;
        flex-wrap: nowrap;
        flex-basis: 100%;
    }

    &__details > *{
        margin: 0
    }

    & .action-buttons {
        flex-shrink: 0;

        & .btn + .btn, .btn + .dropdown {
            margin-left: $spacer / 2;
        }

        & .dropdown {
            display: inline-block;
        }
    }

    .modal-fullscreen & {
      padding: 0;
      background: none;
    }
}

// Container for the content-body
.content-body {
    width: 100%;
    margin: $p21-content-spacing-y auto 0;
    padding: 0 $p21-content-spacing-x;

    .modal-fullscreen & {
      padding: 0;
    }
}

#footer {
    margin-left: 180px;
    color: #777777;
    font-size: inherit;
    font-weight: inherit;
    text-shadow: none;
  }


/* =============================================================================
   Full pages
   ========================================================================== */
.content-fullscreen {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: $p21-content-spacing-y $p21-content-spacing-x;
  background-color: $body-bg;
}


/* =============================================================================
   Utilities
   ========================================================================== */
.max-height-12rem {
  max-height: calc(100vh - 12rem);
  overflow-y: auto;
}


/* =============================================================================
   Temporary states
   ========================================================================== */
.is-hidden,
.hide {
  display: none !important;
}

/* =============================================================================
   Print states
   ========================================================================== */
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
  body {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
  a,
  a:visited {
    color: $black;
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;

    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: $black !important;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: $white !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: $border-width solid $border-color !important;
  }
  .dataTables_toolbar {
    display: none;
  }
  .DTTT_Print .data-table thead th {
    border-right: medium none;
    text-shadow: none;
  }
  .DTTT_Print .data-table tbody .group td,
  .DTTT_Print .data-table tbody td.group {
    font-style: italic;
    padding: 5px 10px;
  }
}
/* =============================================================================
   Local Variables
   ========================================================================== */
// Add more width sizes here for custom styling (first value is class name modal-[string], second value is css width %, px, rem, etc)
// !!! Note: these names will be prepended with "modal-"
$modal-sizes: (
  "sm": $modal-sm, 
  "md": $modal-md, 
  "lg": $modal-lg, 
  "xl": $modal-xl,
  "w-600px": 600px
);

/* =============================================================================
   Modal Base
   ========================================================================== */
.modal-content {
  box-shadow: $box-shadow-lg;
}

.modal-header {
  align-items: baseline;
  @include gradient-y($start-color: $gray-100, $end-color: darken($gray-100, 5%), $start-percent: 0%, $end-percent: 100%);
  box-shadow: 0 1px 0 $white inset;
}

.modal-title {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  text-shadow: 0 1px 0 $white;
  color: $gray-800;
}

.modal-body__callout {
  max-height: 400px;
  overflow-y: auto;
  margin: $modal-inner-padding auto;
  padding: $modal-inner-padding;
  background: $gray-100;
  border: $border-width solid  $border-color;
  border-radius: $border-radius;
}

// Prevent modals from expanding the full width on lower resolutions
// Add custom modal size classes to the modal-sizes map at the top of this code
@each $name, $size in $modal-sizes {
  .modal-#{$name} {
    max-width: $size;
    margin: $modal-dialog-margin-y-sm-up auto;

    @include media-breakpoint-down(xs)  {
      max-width: 100%;
      margin: $modal-dialog-margin-y-sm-up;
    }
  }
}

.modal-footer {
  padding: $modal-inner-padding;

  .modal-body + & {
    padding-top: 0;
  }

  .modal-body & {
    padding: $modal-inner-padding 0 0 0;
  }

  > * {
    margin: 0;
  }

  & .btn {
    margin: 0 0 0 $modal-footer-margin-between;
  }

  & .link {
    margin-right: auto;
  }
}


/* =============================================================================
   Modal Dialog Base (Deprecated Bootstrap 3 styling used in at least
   - Tasklog.scala.html
   - Metadatalog.scala.html
   - login.scala.html 
   - dataPackage.scala.html
   - cells.js
   ========================================================================== */
  .bootstrap-dialog {

    // Make this class's default size large
    & .modal-dialog {
      @include media-breakpoint-up(lg) {
        max-width: $modal-lg;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      width: 100%;
    }

    &-title {
      @extend .modal-title;
      display: inline-block;
      vertical-align: bottom;
      margin-bottom: 0;
      line-height: $modal-title-line-height;
    }

    &-close-button {
      margin-left: auto;
    }
  }

/* =============================================================================
   Modals - Fullscreen
   ========================================================================== */
.modal-fullscreen {
  width: 100%;
  margin: 0;
  max-width: 100%;
  min-height: 100%;
  overflow: hidden;

  &.show ~ .modal-backdrop.show {
    display: none;
  }

  & .modal-dialog {
    margin: 0;
    max-width: 100%;
  }

  &.fade .modal-dialog {
    transform: translate(0, 25%);
    transition: transform 0.8s ease-in-out;
  }

  &.show .modal-dialog {
    transform: translate(0, 0);
  }

  & .modal-content {
    overflow-y: auto;
    border: 0;
    border-radius: 0;
    height: 100vh;
    background-color: $gray-200;
  }

  & .modal-content .modal-header, & .modal-content .modal-footer {
    background: none;
    border: none;
  }

  & .modal-header {
    padding: $modal-inner-padding $modal-inner-padding 0;
  }

  & .modal-header .close {
    font-size: 2rem;
    opacity: 0.5;
  }

  & .modal-title {
    margin: 0 0 .25rem;
    color: $gray-700;
    font-size: 1.75rem;
    font-weight: normal;
    line-height: 1.2;
    text-shadow: 0 1px 0 $white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  & .modal-footer {
    padding: 0 $modal-inner-padding $modal-inner-padding;
  }

  & .modal-body {
    overflow-y: auto;
    padding: 1rem;
  }
}


/* =============================================================================
    Modals - Sidebar
   ========================================================================== */
.sidebar-modal-backdrop.show {
  z-index: $zindex-modal-backdrop - 1; //Make this number 1 less than the modal backdrop z-index
  opacity: 0;
}
  
.sidebar-modal {
  height: 100%;
  z-index: $zindex-modal - 1; //Make this number 1 less than the modal z-index

  & .modal-dialog {
    max-width: 450px;
    max-height: calc(100% - #{$p21-modal-sidebar-top-spacing});
    height: calc(100% - #{$p21-modal-sidebar-top-spacing});
    margin: $p21-modal-sidebar-top-spacing 0 0 auto;
  }

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    @include transition($modal-slide-in-transition);
    transform: translate(100%, 0);
  }

  // When fading in the modal, animate it to slide down
  &.show .modal-dialog {
    transform: translate(0, 0);
  }

  & .modal-content {
    max-height: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
}

// Displays on the left side of the screen
.sidebar-modal.is-left {
  & .modal-dialog {
    margin: $p21-modal-sidebar-top-spacing auto 0 $p21-sidebar-global-width;
  }

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    transform: translateX( calc(-100% - #{$p21-sidebar-global-width}));
    transition: transform 0.5s ease-out;
  }

    // When fading in the modal, animate it to slide down
  &.show .modal-dialog {
      transform: translateX(0);
  }
}

// Specific to the Notifications modal at the moment
.sidebar-modal.has-arrow {
  & .modal-dialog:before {
      content:'';
      display:block;
      width:0;
      height:0;
      position:absolute;
      z-index: 1;
      border-top: $p21-modal-sidebar-arrow-size solid transparent;
      border-bottom: $p21-modal-sidebar-arrow-size solid transparent;
      border-right: $p21-modal-sidebar-arrow-size solid $modal-content-bg;
      left: $p21-modal-sidebar-arrow-size * -1;
      bottom: 130px;
  }
}

// Sidebar Modal header and toolbar
.modal-header--sidebar {
  position: relative;
  flex-direction: column;
  padding: 0;
  background: $gray-700;
  box-shadow: none;
  border: none;
  border-radius: 0;
  transition: background-color 0.3s;

  .is-resolved & {
    background-color: $success;
  }

  .is-archived & {
    background-color: $warning;
  }

  .is-filtered & {
    background-color: $info;
  }

  & .title-container {
    padding: $modal-header-padding-y $p21-modal-sidebar-inner-padding ($modal-header-padding-y / 2);
  }

  & .modal-title {
    align-self: flex-start;
    margin-top: $spacer / 2;
    font-size: $font-size-base * 2;
    font-weight: $font-weight-normal;
    color: $white;
    text-shadow: none;
  
    & .link {
      display: inline-block;
      margin-left: 0.75rem;
      font-size: $font-size-base;
      font-weight: $font-weight-base;
      color: $white;
      opacity: 0.6;
      transition: $transition-fade;
    }
  
    & .link:hover {
      opacity: 1;
    }
  }

  & .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    color: $white;
    font-weight: $font-weight-normal;
    text-shadow: none;
    opacity: 0.75;
    cursor: pointer;
  }
  
  & .close:not(:disabled):not(.disabled):hover {
    opacity: 1;
  }

  & .has-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - (#{$modal-header-padding} * 2));
  }

  & .search {
      flex: 1 1 auto;
      margin: ($spacer / 2) $modal-header-padding-y 0 $modal-header-padding-y;

      &__label,
      &__input {
          width: 100%;
      }
  }

  & .modal-tabs {
      margin-top: $p21-modal-sidebar-inner-padding / 2;
      padding: 0 $p21-modal-sidebar-inner-padding 0;
  }

  & .nav-tabs {
      margin-bottom: 0;
      border: 0;
  }
}

// Skeleton container for the toolbar
// Specific section styling would be found in other UI stylesheets (e.g. comments, etc...)
.modal-toolbar {
  display: flex;
  z-index: 10;
  width: 100%;
  padding: ($modal-header-padding-y / 4) $p21-modal-sidebar-inner-padding;
  background-color: $gray-100;
  box-shadow: 0 1px 3px 0 rgba(0,0,0, 0.25);

  &__left-container {
    width: 50%;
  }

  &__right-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: $form-grid-gutter-width;
  }
}

// Modal body and content
.modal-body--sidebar {
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
  
  
/* =============================================================================
   Modal Overrides
   ========================================================================== */
// Where clause builder modal
.builder-message-header {
  font-size: $h4-font-size;
  margin: 0 0 $modal-inner-padding / 4;
}

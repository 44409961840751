/* =============================================================================
   Tabs Placeholder Animation
   ========================================================================== */
%tab-item {
  @include gradient-y($start-color: $gray-300, $end-color: $gray-400, $start-percent: 10%, $end-percent: 100%); 
  position: relative;
  border-color: $nav-tabs-link-hover-border-color;
  color: $gray-700;
  text-shadow: 0 1px 0 $gray-100;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background: $gray-900;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform 0.3s;
  }

  &:visited {
    color: $gray-700;
  }

  &:hover {
    color: $gray-900;

    &::before {
      transform-origin: 0% 50%;
      transform: scaleX(1);
    }
  }
}


/* =============================================================================
   Tabs Base
   ========================================================================== */
.nav-tabs {
  flex-wrap: nowrap;
  margin-bottom: 0.625rem;
  overflow-y: hidden;
  overflow-x: auto;

  & .nav-item {
    margin-right: $spacer / 4;
    white-space: nowrap;
  }
  
  & .nav-link {
    @include gradient-y($start-color: $gray-300, $end-color: $gray-400, $start-percent: 10%, $end-percent: 90%); 
    @extend %tab-item;
  
    &.active {
      @include gradient-y($start-color: $white, $end-color: $gray-200, $start-percent: 10%, $end-percent: 75%); 
    }

    &.disabled {
      opacity: 0.7;
    }
  }
}


/* =============================================================================
   React Tabs Base
   ========================================================================== */
.react-tabs__tab-list {
  padding: 0;
  border-bottom: $border-width solid $nav-tabs-border-color;
}

.react-tabs__tab {
  @extend %tab-item;
  display: inline-block;
  margin-right: $spacer / 4;
  padding: $nav-link-padding-y $nav-link-padding-x;
  border: $border-width solid $nav-tabs-border-color;
  border-bottom: 0;
  border-radius: $nav-tabs-border-radius $nav-tabs-border-radius 0 0;
  cursor: pointer;

  &--disabled {
    color: $gray-500;
    cursor: default;
  }

  &--selected {
    @include gradient-y($start-color: $white, $end-color: $gray-200, $start-percent: 10%, $end-percent: 65%); 
    color: $nav-tabs-link-active-color;
  }

  &:focus-visible {
    box-shadow: $btn-focus-box-shadow;
    border-color: $primary;
    outline: none;
  }
}

.react-tabs__tab-panel {
  display: none;

  &--selected {
    display:  block;
    position: relative;
  }
}


/* =============================================================================
   Tabs Overrides
   ========================================================================== */
/* =============================================================================
   Progress Base
   ========================================================================== */
.progress--dark {
  background-color: $gray-600;
}

.progress-bar {
    border-radius: $progress-border-radius;
}


/* =============================================================================
   Progress Overrides
   ========================================================================== */
.is-indeterminate {
    position: relative;

    & .progress-bar {
        position: absolute;
        height: 100%;
        width: 50% !important;
        background-color: $primary !important;
        border-radius: $progress-border-radius;
        will-change: left, right;
        animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: $primary !important;
        border-radius: $progress-border-radius;
        will-change: left, right;
        animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
    }
}

@keyframes indeterminate {
    0% {
      left: -90%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }
  
  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
/* =============================================================================
// Dropdown Base
  ========================================================================== */
// Add the FA chevron-down icon
.dropdown-toggle {
  &::after {
    content: "";
    display: inline-block;
    width: $caret-width;
    height: 1em;
    margin-left: $caret-spacing;
    vertical-align: $caret-vertical-align;
    background: transparent url('/assets/images/icons/fa/chevron-down-solid.svg') no-repeat left center;
  }

  & > .fas, & > .far, & > .fal, & > .fa-solid, & > .fa-regular, & > .fa-light {
    margin-right: 0.25rem;
  }
}

.dropdown-menu {
  box-shadow: $box-shadow;

  & li {
    cursor: default;
  }
}

.dropdown {
  &__label--value {
    margin-left: 0.188rem;
    font-weight: $font-weight-bold;
  }
}

// Animate menu on. This only works if Bootstrap 4.0 dynamic positioning with Popper.js is turned off.
// If not Popper.js will add inline styling using transform and position that conflicts with this code.
// Add data-display="static" to the dropdown toggle to turn it off individually.
// https://getbootstrap.com/docs/4.6/components/dropdowns/#responsive-alignment
.dropdown-menu--animate {
  display: block;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
  transform-origin: top left;
  transition: opacity 0.06s linear 0s, transform 0.24s cubic-bezier(0, 0, 0.2, 1) 0s;

  &.dropdown-menu-right {
    transform-origin: top right;
  }

  &.show {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
    transition: opacity 0.03s linear 0s, transform 0.12s cubic-bezier(0, 0, 0.2, 1) 0s;
  }
}

.dropdown-item {
  border: $input-btn-border-width solid transparent;

  &:visited {
    color: $dropdown-link-color;
  }

  &.active:not(.disabled):not(:disabled),
  &:active:not(.disabled):not(:disabled) {
    border-color: $dropdown-link-active-border;
    color: $dropdown-link-active-color;

    &:hover {
      background: $dropdown-link-hover-bg;
      border-color: $dropdown-link-hover-bg;
    }
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
    outline: none;
  }

  &:focus-visible {
    outline-color: $input-btn-focus-color;
  }
}

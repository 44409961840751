/* =============================================================================
   Breadcrumb Base
   ========================================================================== */
.breadcrumb {
  border-bottom: $border-width solid $gray-300;
  color: $gray-600;

  &-item a, &-item a:visited {
    position: relative;
    align-items: baseline;
    color: $gray-600;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background: $gray-900;
      bottom: 0;
      left: 0;
      pointer-events: none;
      transform-origin: 100% 50%;
      transform: scaleX(0);
      transition: transform 0.3s;
    }
  }

  &-item a:hover {
    color: $gray-900;

    &:hover::before {
      transform-origin: 0% 50%;
      transform: scaleX(1);
    }
  }

  &-item.active {
    font-weight: $font-weight-bold;
  }

  &__icon {
    font-size: 1em;
    margin-right: 0.25rem;
  }
}

/* =============================================================================
   Breadcrumb Overrides
   ========================================================================== */
// Found in the Define/Spec Compare History
.comparison-breadcrumbs {
  display: inline-block;

  & .alert {
    display: flex;
  }

  & li {
    color: $gray-800;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & li:nth-child(n + 2) {
    display: none;
  }

  & ul > li + li:before {
    padding: 0 0.25rem;
    color: $gray-800;
    content: "/\00a0";
  }
}

.vs {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin: 0 $spacer;
}

/* === For tablets ================================== */
@media (min-width: 768px) and (max-width: 991px) {
  .comparison-breadcrumbs li.ellipsis:nth-last-child(-n + 4) {
    display: inline-block;
  }
  .comparison-breadcrumbs li.ellipsis {
    max-width: 90px;
  }
}
/* === For desktops ================================== */
@media (min-width: 992px) and (max-width: 1199px) {
  .comparison-breadcrumbs li.ellipsis:nth-last-child(-n + 6) {
    display: inline-block;
  }
  .comparison-breadcrumbs li.ellipsis {
    max-width: 102px;
  }
}
/* === For large desktops ================================== */
@media (min-width: 1200px) and (max-width: 1799px) {
  .comparison-breadcrumbs li.ellipsis:nth-last-child(-n + 6) {
    display: inline-block;
  }
  .comparison-breadcrumbs li.ellipsis {
    max-width: 136px;
  }
}
/* === For yooge desktops ================================== */
@media (min-width: 1800px) {
  .comparison-breadcrumbs li.ellipsis:nth-last-child(-n + 6) {
    display: inline-block;
  }
  .comparison-breadcrumbs li.ellipsis {
    max-width: 236px;
  }
}
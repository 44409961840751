.highcharts-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.highcharts-background {
  fill: #fafafa;
}

.highcharts-title {
  font-size: 1.5rem;
}

.highcharts-subtitle {
  font-size: .85rem;
}

.highcharts-axis-labels {
  font-size: .85rem;
}

.highcharts-tooltip-box {
  stroke: #666;
}

.highcharts-point {
  &.open {
    fill: theme-color("open");
    stroke: theme-color("open");
  }

  &.fix-now {
    fill: theme-color("fix-now");
    stroke: theme-color("fix-now");
  }

  &.fix-later {
    fill: theme-color("fix-later");
    stroke: theme-color("fix-later");
  }

  &.to-close {
    fill: theme-color("to-close");
    stroke: theme-color("to-close");
  }

  &.closed {
    fill: theme-color("closed");
    stroke: theme-color("closed");
  }

  &.fixed {
    fill: theme-color("fixed");
    stroke: theme-color("fixed");
  }

  &.reject {
    fill: theme-color("reject");
    stroke: theme-color("reject");
  }

  &.high, &.error {
    fill: theme-color("high");
    stroke: theme-color("high");
  }

  &.medium, &.warning {
    fill: theme-color("warning");
    stroke: theme-color("warning");
  }

  &.low, &.notice {
    fill: theme-color("low");
    stroke: theme-color("low");
  }
}

/* =============================================================================
  Highcharts Header Bar
  
  Container on top of highcharst that contains filters and help links 
  (e.g., Analytics > Scores)
   ========================================================================== */
.chart-P21 {
  position: relative;
  margin-bottom: $p21-content-spacing-y;
}
  
.chart-P21__header {
  display: flex;
  align-items: baseline;

  &__help {
    align-self: flex-end;
    margin-left: auto;
    text-align: right;
  }
}

.chart-P21__filters {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  &--checkbox-underneath {
    position: absolute;
    bottom: calc((#{$custom-control-indicator-size} + (#{$input-border-width} * 2) + 0.5rem + 10px) * -1); /* (#{$custom-control-indicator-size} + (#{$input-border-width} * 2) + .highcharts-container 0.5rem margin bottom + .dataTables_toolbar 10px padding) * -1 */
    left: 30px; /* 20px for page padding & 10px for dataTables_toolbar */
    z-index: 10;
  }
}
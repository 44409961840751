/* =============================================================================
   These are single pages for Enterprise or Community 
   mostly when you are not signed in
   ========================================================================== */

/* =============================================================================
   Unauthorized Views (login, password reset, etc)
   ========================================================================== */
.unauthed-view {
    width: 100%;    
    height: 100%;
    background: url(../../images/login-bg.png) repeat-x #2d2d2d;

    #wrapper {
        background-color: transparent;
    }

    & .alert {
        text-align: center;
        -moz-border-radius: 0;
        -khtml-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
    }
    
    & #content {
        width: 450px;
        height: 310px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -155px 0 0 -225px;
        background: none;
        padding-top: 0;
        padding-bottom: 0;
        overflow: visible;
    }  
    
    & #content.sso-on {
        height: 405px;
        margin: -203px 0 0 -225px;
    }

    & h1 {
        background: url(../../images/logo-login.png) no-repeat center;
        color: #000;
        height: 50px;
        margin: 0 auto 10px auto;
        text-indent: -9999px;
        width: 450px;
    }
    
    & .content-box {
        padding: 30px;
        background: url(../../images/login-box-bg.png) repeat-x #b3b3b3;
        -moz-border-radius: 5px;
        -khtml-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        margin: 0 0 10px;
        overflow: hidden;
    }  

    & div.row-sso {
        text-align: center;
        margin-bottom: 0;
    }

    & .btn-login {
        float: right;
    }

    & .btn-sso {
        font-size: 1rem;
        text-transform: uppercase;
    }

    & #content.sso-on .link-row {
        -webkit-margin-after: 20px; /* fix for chrome margin */
    }

    & a {
        color: #535353;
        text-decoration: underline !important;
    }
     
    & .copyright {
        font-size: 11px;
        color: #989898;
        text-align: center;
        margin-top: 4px;
    }
}

// Forms section
.unauthed-view form {
    & .button-row {
        margin-bottom: $form-grid-gutter-width;
    }

    // Links to forgot password and contact support
    & .link-row {
        text-align: right;

        & a {
            margin-left: 43px;
            font-size: 12px;
        }

        & .link-icon {
            margin-right: 0.25rem;
        }
    }
} 

// Form section: inputs
.unauthed-view form .field-row {
    display: flex;
    margin-bottom: $form-grid-gutter-width;
    padding: 0;
    border: 0;
    text-align: right;

    // Labels
    & .col-form-label {
        display: inline-block;
        flex: 0 0 25%;
        max-width: 25%;
        color: $black;
        font-size: 1rem;
        font-weight: normal;
        text-shadow: none;
        text-align: right;

        // Containers for inputs to the right of the label
        & + div {
            flex: 0 0 75%;
            max-width: 75%;
            padding: 0;
        }     
    
        & + div > input {
            width: 100%;
            margin-right: 0;
        }
    
        & + div > span {
            display: inline;
        }
    } 
}

// Form section: single sign on
.unauthed-view fieldset.sso {
    margin-top: $spacer;
    border: none;
    border-top: 1px solid #000;

    & legend {
        text-align: center;
        font-size: 16px;
        width: auto;
        border: none;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: $spacer;
        -webkit-margin-before: 0; /* fix for chrome margin */
        border: 0;
    }
}


/* =============================================================================
   Login-specific styles
   ========================================================================== */
a#sso_login:link,
a#sso_login:hover,
a#sso_login:visited,
a#sso_login:active {
    color: #ffffff;
    text-decoration: none !important;
}


/* =============================================================================
   Browser Requirements Message
   ========================================================================== */
.browser-support-reqs {
    display: none;
    margin: 5% auto $spacer;
    color: $white;

    & p {
        margin-bottom: 0.25rem;
    }

    & a:link,
    & a:visited {
        color: $link-color;
        text-decoration: none !important;
    }

    & a:hover {
        color: lighten($link-color, 15%);
        text-decoration: underline !important;
    }
}

.unsupported-browser {

    & .login-container {
        display: none;
    }

    & .browser-support-reqs {
        display: block;
    }

    & #content,
    & #content.sso-on {
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        margin: 0 auto;
        text-align: center;
    }

     & .content-box {
        background: transparent;
    }
}


/* =============================================================================
     License Agreement built around unauthed-view
    ========================================================================== */
body.unauthed-view {
    & #license-content {
        width: 75%;
        height: 50%;
        position: absolute;
        left: 25%;
        top: 25%;
        margin: -155px 0 0 -225px;
        background: none;
        padding-top: 0;
        padding-bottom: 20px;
        overflow: visible;
    }

    & #license-agreement-buttons {
        padding-top: 15px;
        float: right;
    }

    & #license-content label {
        padding-right: 20px;
    }

    & #license-content input {
        margin-right: 5px;
    }
} 
  
.license-agreement {
    max-height: 60vh;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: #cccccc;
    border-radius: 4px;
    cursor: not-allowed;
    background-color: #eeeeee;
    padding-top: 6px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 12px;
    overflow-y: scroll;
}
  
  
/* =============================================================================
     Community Mode
    ========================================================================== */
.privacy-mode {
    color: #c0392b;

    &-help h3 i {
        width: 30px;
        margin-left: 20px;
    }
}
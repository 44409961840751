$p21-link-box-shadow-focus: 0 0 0 $btn-focus-width rgba(mix(color-yiq($link-color), $link-color, 15%), .5) !default;
$p21-btn-bg-pos-y: 1rem;

/* =============================================================================
   Links Base
   ========================================================================== */
a, .link {
  text-decoration: $link-decoration;
  color: $link-color;
  align-items: baseline;
  transition: all 0.15s ease-in-out;

  &:visited {
    color: $link-color;
  }

  &:hover, &:focus {
    cursor: pointer;
    color: $link-hover-color;
  }

  &:focus-visible {
    outline: 0;
    border-radius: 0.2rem;
    text-decoration: underline;
    box-shadow: $p21-link-box-shadow-focus;
  }

  &:active {
    color: darken($link-hover-color, 5%);
  }
}

.link-dark, .link-dark:visited {
  position: relative;
  z-index: 0;
  align-items: baseline;
  color: $dark;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      width: calc(100% + (#{$btn-padding-x-sm} * 2));
      height: calc(100% + (#{$btn-padding-y-sm} * 2));
      background: $dropdown-link-hover-bg;
      opacity: 0;
      bottom: -$btn-padding-y-sm;
      left: -$btn-padding-x-sm;
      border-radius: $btn-border-radius-sm;
      pointer-events: none;
      transition: opacity 0.2s;
    }

  &:hover {
    color: darken($dark, 10%);

    &:hover::before {
      opacity: 1;
      transition: opacity 0.2s;
    }
  }
}


/* =============================================================================
  Buttons Base
  ========================================================================== */
.btn {
  text-decoration: $link-decoration;

  &.disabled, &:disabled, &[disabled] {
    opacity: $btn-disabled-opacity;
    cursor: default !important;
    pointer-events: none;
  }

  &:focus, &.focus {
    outline: 0;
    text-decoration: none;
    box-shadow: none;
  }

  // for button groups with a single button. used to be :only-type, but changed to prevent mixture of anchors and buttons in same button bar from not respecting the border radii
  .btn-group &:only-child {
    border-radius: $btn-border-radius;
  }

  .btn-group &-sm:only-child {
    border-radius: $btn-border-radius-sm;
  }

  .btn-group &-lg:only-child {
    border-radius: $btn-border-radius-lg;
  }

  // reset the last nested btn groups i.e. in Define/Standard/Terminologies because these can have <iframe>s that prevent the :only-child above
  .btn-group > .btn-group:first-child > &:only-of-type {
    border-top-left-radius: $btn-border-radius;
    border-bottom-left-radius: $btn-border-radius;
  }

  .btn-group > .btn-group:last-child > &:only-of-type {
    border-top-right-radius: $btn-border-radius;
    border-bottom-right-radius: $btn-border-radius;
  }

  .btn-group > .btn-group:first-child > &-sm:only-of-type {
    border-top-left-radius: $btn-border-radius-sm;
    border-bottom-left-radius: $btn-border-radius-sm;
  }

  .btn-group > .btn-group:last-child > &-sm:only-of-type {
    border-top-right-radius: $btn-border-radius-sm;
    border-bottom-right-radius: $btn-border-radius-sm;
  }

  .btn-group > .btn-group:first-child > &-lg:only-of-type {
    border-top-left-radius: $btn-border-radius-lg;
    border-bottom-left-radius: $btn-border-radius-lg;
  }

  .btn-group > .btn-group:last-child > &-lg:only-of-type {
    border-top-right-radius: $btn-border-radius-lg;
    border-bottom-right-radius: $btn-border-radius-lg;
  }
}

/* =============================================================================
   Buttons Base: Variants by Color
   ========================================================================== */
// Add gradient to each button color in the theme
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    border-color: $p21-btn-border-color;
    background-size: 100% calc(100% + #{$p21-btn-bg-pos-y});
    transition: all 0.15s ease-in-out;
  
    &#{$p21-not-disabled}:hover {
      text-decoration: none;
      background-position: 0 ($p21-btn-bg-pos-y * -1);
    }

    &#{$p21-not-disabled}:focus-visible, &#{$p21-not-disabled}.focus-visible {
      outline: 0;
      box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($value), $value, 15%), .5);
    }

    &#{$p21-not-disabled}:active:focus {
      box-shadow: inset 0 2px 4px rgba($black, 0.3), inset 0 1px 2px rgba($black, 0.2);
    }

    &.disabled, &:disabled, &[disabled] {
      border-color: $value;
    }
  }

  // Light or default colors
  @if($value == $light) {
    .btn-#{$color} {
      @include gradient-y($start-color: $value, $end-color: darken($value, 10%), $start-percent: 0%, $end-percent: calc(100% - #{$p21-btn-bg-pos-y}));
      color: $gray-800;
      text-shadow: 0 1px 1px rgba($white, 0.75);
      box-shadow: inset 0 1px 0 rgba($white, 0.2), inset 0 1px 2px rgba($black, 0.05);

      &:visited {
        color: $gray-800;
      }

      &#{$p21-not-disabled}:hover, &#{$p21-not-disabled}:focus, &#{$p21-not-disabled}.focus, &#{$p21-not-disabled}:active, &#{$p21-not-disabled}.active {
        border-color: rgba($black, 0.2) rgba($black, 0.2) rgba($black, 0.5);
        color: $black;
      }
    }
  } @else {
    .btn-#{$color} {
      @include gradient-y($start-color: $value, $end-color: darken($value, 15%), $start-percent: 0%, $end-percent: calc(100% - #{$p21-btn-bg-pos-y}));
      color: $white;
      text-shadow: $p21-btn-text-shadow;

      &:visited {
        color: $white;
      }
    }
  }
}

// Button Link
.btn-link {
  &#{$p21-not-disabled}:focus-visible, &#{$p21-not-disabled}.focus-visible {
    box-shadow: $p21-link-box-shadow-focus;
  }
}

/* =============================================================================
   Links Overrides
   ========================================================================== */
.btn-link--ellipsis {
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0;
}

/* =============================================================================
   Buttons Overrides
   ========================================================================== */

// reset button borders to have darker borders to offset visually from the background of the toolbar
.dataTables_toolbar .btn-light {
  border-color: $p21-btn-border-color-datatable;
  font-weight: $p21-font-weight-semi-bold;
  &:not(:disabled):not(.disabled):not([disabled]):hover {
    border-color: darken($p21-btn-border-color-datatable, 7%);
  }
}

// link on white backgrounds, i.e. in Data Table toolbars for external links   
.link-on-white {
  display: inline-block;
  padding: $btn-padding-y $btn-padding-x;
  border: $btn-border-width solid $p21-btn-border-color-datatable;
  border-radius: $border-radius;
  background: rgba($white, .8);

  &--sm {
    padding: $btn-padding-y-sm $btn-padding-x-sm;
    border-radius: $border-radius-sm;
    font-size: $btn-font-size-sm;
    font-weight: $p21-font-weight-semi-bold;
  }
}

.btn-group--has-inputs {
  & .btn.active#{$p21-not-disabled} {
    @include gradient-y($start-color: darken($primary, 15%), $end-color: darken($primary, 7%), $start-percent: 10%, $end-percent: 60%);
    color: $white;
    text-shadow: none;
  }

  & .btn.active#{$p21-not-disabled}:not(.focus) {
    box-shadow: none;
  }

  & .btn-group__input {
    display: none;
  }
}


/* =============================================================================
   Buttons Overrides: Circular Icons
   ========================================================================== */
.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $p21-btn-icon-size;
  height: $p21-btn-icon-size;
  border-radius: 50%;
  background-color: transparent;
  transition: $transition-base;

  &#{$p21-not-disabled}:hover {
    background-color: rgba($white, 0.75);
    cursor: pointer;
  }

  &.disabled, &:disabled, &[disabled] {
    opacity: $btn-disabled-opacity;
    cursor: default !important;
    pointer-events: none;
  }

  &#{$p21-not-disabled}:focus-visible, &#{$p21-not-disabled}.focus-visible {
    outline: 1px solid $primary;
    background-color: rgba($primary, 0.15);
  }

  & .icon {
    color: $p21-btn-icon-font-color;
    font-size: $p21-btn-icon-font-size;
  }

  &--sm {
    width: $p21-btn-icon-size-sm;
    height: $p21-btn-icon-size-sm;
  }
}

@each $color, $value in $theme-colors {
  .btn-icon--#{$color} {
    &#{$p21-not-disabled}:hover {
      background-color: rgba($value, 0.15);
      cursor: pointer;

      & .icon {
        color: $value;
      }
    }
  }
}

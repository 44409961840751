/* =============================================================================
   Typography Base
   ========================================================================== */
h1, .h1 {
    margin-bottom: $headings-margin-bottom / 2;
    font-weight: $font-weight-normal;
    color: $gray-700;
    text-shadow: 0 1px 0 $white;
}

.h1--page-title {
    margin-top: 0;
}

// Code
pre {
    padding: $alert-padding-y $alert-padding-x;
    background: $gray-200;
    border-radius: $border-radius-sm;

    .modal-body & {
        max-height: 450px;
        margin-bottom: 0;
    }
}

// Lists
ul,
ol {
  list-style: none outside none;
}

.has-disc {
    list-style-type: disc;
    padding-left: $spacer * 2;
}

.has-decimal {
    list-style-type: decimal;
    margin-bottom: $spacer / 3;
}

del,
del * {
  background-color: $p21-cell-bg-danger;
}

ins,
ins * {
  background-color: $p21-cell-bg-success;
}

// definition list of key-value pairs
.list-metadata {
    line-height: 1;
    margin: 0;
    padding: 0;

    &__key,
    &__value {
        margin: 0;
        padding: 0;
    }

    &__key {
        margin-bottom: .375rem;
        font-size: $p21-font-size-semi-sm;
        font-weight: normal;
        color: $gray-600;
    }
    
    &__value {
        color: $gray-900;
    }
}

/* =============================================================================
   Typography Overrides
   ========================================================================== */
// Utilities
.strike-through {
    text-decoration: line-through;
}

.wrap-long-text {
    word-break: break-all;
    word-wrap: break-word;
}

// Darker font colors that match the alert colors
@each $color, $value in $theme-colors {
    .text-#{$color}-darker {
        color: theme-color-level($color, $alert-color-level) !important;
    }
}

.line-height--none {
    line-height: 1;
}

.line-height--sm {
    line-height: 1.3;
}

.line-height--md {
    line-height: $line-height-base;
}

.line-height--lg {
    line-height: 1.6;
}

// Darker font colors that match the alert colors
@each $color, $value in $theme-colors {
    .text-#{$color}-darker {
      color: theme-color-level($color, $alert-color-level) !important;
    }
}


// Fix data errors modal
.hot-errors {
    margin-top: $spacer;
    padding: $alert-padding-y $alert-padding-x;
    background: $gray-200;
    border-radius: $border-radius-sm;

    .modal-body & {
        max-height: 450px;
        margin-bottom: 0;
    }

    & .hot-error-table-header {
        min-width: 125px;
    }
}

// Notifications
.activity-content {
    word-wrap: break-word;
}

.unread {
    font-weight: $font-weight-bold;
}

// Comparison h1 header 
.comparison-header-highlight {
    font-weight: $p21-font-weight-semi-bold;
    color: $gray-800;
  }

// CRF Page Loss Warning Modal
.crf-data-loss-modal {
    & ol {
        list-style: decimal;
    } 

    & ul {
        list-style: disc;
    }  

    & p {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
    }
}

.font-size-sm {
    font-size: $font-size-sm;
}
.font-size-md {
    font-size: $font-size-base;
}
.font-size-lg {
    font-size: $font-size-lg;
}
/* =============================================================================
// Local Variables, Mixins, and Placeholders
  ========================================================================== */
// Copied from .form-control
%select-control-style {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Remove select outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus($ignore-warning: true);

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }
  
    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
  }
}

  // Dropdown arrow
  @mixin dropdown-arrow ($has-child:true) {
    display: inline-block;
    top: 50%;
    width: $p21-select-choice-arrow-size * 2;
    height: $p21-select-choice-arrow-size * 2;
    line-height: $line-height-base;
    background: transparent;
    border: none;
    border-radius: $rounded-pill;
    box-shadow: none;
    transform: translateY(-50%);
    transition: $badge-transition;
    
    @if $has-child{
      & > *:first-child {
        background: transparent url('/assets/images/icons/fa/chevron-down-solid.svg') no-repeat center !important;
        background-size: $p21-select-choice-arrow-size !important;
      }
    } @else {
      background: transparent url('/assets/images/icons/fa/chevron-down-solid.svg') no-repeat center !important;
      background-size: $p21-select-choice-arrow-size !important;
    }
  }
  
  %select-button-active {
    border-radius: $input-border-radius;
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }

  %close-default {
    order: -1;
    opacity: 0.6;
    padding: $badge-padding-y ($badge-padding-y / 2) $badge-padding-y $badge-padding-y;
    color: $close-color;
    background-color: rgba($danger, 0);
    background-position: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: $btn-transition;
  }

  %close-hover {
    opacity: 1;
    color: $danger;
    background-color: rgba($danger, 0.15);
    background-position: center;
  }


/* =============================================================================
// Select Base
//
// BS3 Select2 dropdown (e.g., Analytics > Company Scores, Config > Studies)
  ========================================================================== */
.select2-drop-mask {
    border: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: $zindex-dropdown - 1;
    /* styles required for IE to work */
    background-color: $black;
    filter: alpha(opacity=0);

    // When inside a modal
    .modal-backdrop ~ & {
      z-index: $zindex-modal + 1;
    }
}

// Global select container to open dropdown
.select2-container {
  // Add spacing to the right for required/optional text
  margin-right: $form-grid-gutter-width;

  // Link to open dropdown (default)
  & .select2-choice {
    @extend %select-control-style;
    display: block;
    background-image: none;
    border: $input-border-width solid $input-border-color;
    box-shadow: $input-box-shadow;

    &:hover {
      border-color: $input-focus-border-color;
    }

    .has-error & {
      border-color: $danger;
    }

    .has-warning & {
      border-color: $warning
    }
  }

  // Container list of multi-select choices
  & .select2-choices {
    @extend %select-control-style;
    display: block;
    position: relative;
    height: auto;
    margin: 0;
    border: $input-border-width solid $input-border-color;
    cursor: text;
    overflow: hidden;
    background-image: none;
    box-shadow: $input-box-shadow;

    &:hover {
      border-color: $input-focus-border-color;
    }

    .has-error & {
      border-color: $danger;
    }

    .has-warning & {
      border-color: $warning
    }
  }

  // Link to open dropdown (active)
  &.select2-container-active {
    & .select2-choice,
    & .select2-choices {
      @extend %select-button-active;
    }
  }

  // Arrow icon default and hover state
  & .select2-choice .select2-arrow {
    @include dropdown-arrow;

    &:hover {
      background: rgba($custom-select-indicator-color, 0.15);
    }
  }

  // Placeholder text
  & + input::placeholder,
  & .select2-default {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled state
  &.select2-container-disabled {
    // Link to open dropdown (disabled)
    & .select2-choice {
      background-color: $input-disabled-bg;
      box-shadow: none;
      pointer-events: none;
      cursor: default;
    }

    // Arrow icon default and hover state
    & .select2-choice .select2-arrow {
        background: transparent;
    }
  }
}

// Search and loading icons
.select2-search {
  padding: 0.25rem;
  
  & input {
    border-radius: $input-border-radius-sm;
    border: $input-border-width solid $input-border-color;
    background-image: url('/assets/images/icons/fa/search-solid.svg');
    background-repeat: no-repeat;
    background-size: 1em 1em;
    background-position-x: calc(100% - 0.25rem);
    background-position-y: center;
  }
}

.select2-search input.select2-active, 
.select2-more-results.select2-active,
.select2-search-field input.select2-active {
  background-image: url('/assets/images/icons/fa/spinner-solid.svg');
  background-repeat: no-repeat;
  background-size: 1em 1em;
  background-position-x: calc(100% - 0.25rem);
  background-position-y: center;
}  

// Global select dropdown menu
.select2-drop {
  position: absolute;
  z-index: $zindex-dropdown;
  margin-top: $input-border-width;
  border-radius: $dropdown-border-radius;
  border: $dropdown-border-width solid $dropdown-border-color;
  background: $dropdown-bg;
  box-shadow: $dropdown-box-shadow;

  // Active
  &.select2-drop-active {
    border: $dropdown-border-width solid $dropdown-border-color;
  }

  // Global dropdown menu search
  &.no-search .select2-search {
    display: none;
  }

  // When inside a modal
  .modal-backdrop ~ & {
    z-index: $zindex-modal + 2;
  }
}

// Dropdown menu for the select2 link
.select2-drop--auto-width {
  width: auto !important;

  & .select2-result-label {
    white-space: nowrap;
  }
}
  
// Global dropdown menu list
.select2-results {
  padding: 0;
  margin: 0.25rem 0;

  & li {
    border: $input-btn-border-width solid transparent;
  }

  // Global list item (hover & active)
  & .select2-highlighted {
    background: $dropdown-link-active-bg;
    border-color: $dropdown-link-active-border;
    color: $dropdown-link-active-color;
    position: relative;

    //@include selected-state;
  }

  // <div> in list item
  & .select2-result-label {
    min-height: $font-size-base * 2;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  }
}

// No results
.select2-no-results,
.select2-searching,
.select2-ajax-error,
.select2-selection-limit {
  padding: $spacer;
  text-align: center;
  color: $text-muted;
}

/* =============================================================================
// Multi-Select Base
  ========================================================================== */
// Filters with checkboxes
.dropdown-menu__option {

  & .dropdown-item, 
  & .custom-control {
    margin-bottom: 0;
  }

  &.active .dropdown-item, 
  &:active .dropdown-item {
    background: $white;
    color: $dropdown-link-active-color;

    &:hover {
      background: $dropdown-link-hover-bg;
    }
  }

}

// Close/clear button for input and multi-select pills
.select2-search-choice-close {
  @extend %close-default;
  display: none;
  position: absolute;
  top: 50%;
  right: $p21-select-choice-arrow-size * 2;
  width: $p21-select-choice-arrow-size * 2;
  height: $p21-select-choice-arrow-size * 2;
  margin: 0;
  font-size: 1rem;
  border-radius: $rounded-pill;
  background-image: url('/assets/images/icons/fa/times-regular.svg');
  background-repeat: no-repeat;
  background-size: $p21-select-choice-arrow-size - $input-padding-y-sm;
  transform: translateY(-50%);

  &:hover {
    @extend %close-hover;
  }

  .select2-allowclear & {
    display: inline;
  }

  // Add small divider between down arrow and clear 'x' icon
  .select2-allowclear & + .select2-arrow::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: $input-border-width;
    height: 1em;
    background: $input-border-color;
    transform: translateY(-50%);
  }

  // Close button for pills (multi-select choices)
  .select2-container-multi & {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 1.25em;
    height: $font-size-base * $line-height-base;
    border-radius: $badge-border-radius 0 $badge-border-radius 0; 
    transform: none;
  }
}

// React and Non-React Select base
.select-control-container .react-select__multi-value, 
.react-select .react-select__multi-value, 
.select2-container-multi .select2-search-choice {
  @include gradient-y(#f4f4f4, #e8e8e8, 50%, 51%);
  display: inline-flex;
  align-items: center;
  vertical-align: text-top;
  margin: 0 $badge-padding-x ($badge-padding-x / 2) 0;
  border: $input-border-width solid $p21-select-choice-border-color;
  border-radius: $badge-border-radius;
  box-shadow: $p21-select-choice-box-shadow;

  &__label, & > div:first-child {
    margin: 0 $badge-padding-x 0 ($badge-padding-x / 2);
    padding: 0;
    font-size: $font-size-base;
    color: $p21-select-choice-color;
  }

  &__remove {
    @extend %close-default;
  }

  &__remove:hover {
    @extend %close-hover;
  }

  &__remove svg {
    width: $font-size-base;
    height: $font-size-base;
  }
}

// Read only pill
.select2-search-choice.select2-locked {
  padding: 0;
  background-image: none;
  background: $gray-100;
  box-shadow: none;
}

.select2-search-field {
  display: inline-block;
  vertical-align: ($badge-padding-x / 2) * -1;
  height: calc($font-size-base * $line-height-base - ($input-border-width * 2));
  margin: 0;
  padding: 0;
  white-space: nowrap;

  & .select2-input {
    min-width: 7rem;
    height: $input-height-inner-half;
    padding: 0 0.25rem;
    margin: 0;
    outline: 0;
    border: 0;
    background: transparent;
  }
}

// Disabled 
.select2-container-disabled {
  & .select2-choices {
    background-color: $input-disabled-bg;
    background-image: none;
    cursor: default;
    pointer-events: none;
    box-shadow: none;
  }

  & .select2-search-choice {
    padding: $badge-padding-y $badge-padding-x;
    border: $input-border-width solid $input-border-color;
    background-image: none;
    background-color: $gray-100;
  }

  & .select2-search-choice .select2-search-choice-close {   
    display: none;
    background: none;
  }
}

/* =============================================================================
// Select Base: Link style
  ========================================================================== */
.custom-select--link {
  display: flex;
  align-items: baseline;
  margin: 0 $spacer ($spacer / 2) 0;

  & .custom-select__arrow,
  & .select2-arrow {
    pointer-events: none;
  }
}

// Label for filters
.custom-select__label {
  flex: 0 0 auto;
  margin: 0 0.25rem 0 0;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $gray-900;
}
  
// Filter link or button for dropdown
.custom-select--link .select2-choice, .custom-select--link .custom-select__btn {
  position: relative;
  height: auto;
  padding: 0 ($dropdown-padding-y / 2);
  line-height: $line-height-base;
  color: $link-color;
  background: rgba($white, 0);
  border: none;
  border-radius: 0;
  box-shadow: none;
  transition: $transition-base;

  &:hover {
    color: $link-hover-color;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: $link-hover-color;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform 0.3s;
  }

  &:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
  }

  &:focus-visible {
    color: $link-hover-color;
    outline-color: $input-btn-focus-color !important;
    outline-style: solid !important;
    outline-width: $input-btn-focus-width !important;
    outline-offset: 0.25rem !important;
  }
}

.custom-select--link .custom-select__btn {
  padding: 0;
}
  
// Text in link/button (default)
.custom-select--link .select2-choice .select2-chosen, .custom-select--link .custom-select__btn .custom-select__text {
  font-size: $font-size-base;
  margin-right: $p21-select-choice-arrow-size * 1.5;
  color: $link-color;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: $transition-base;
}

// Custom styling for just the input text inside the custom select button (default)
.custom-select--link .custom-select__btn .custom-select__text {
  margin-right: 0;
  padding-left: ($dropdown-padding-y / 2);
  padding-right: ($p21-select-choice-arrow-size * 1.5) + ($dropdown-padding-y / 2);

  &.focus, &:focus {
    @extend %select-button-active;
  }
}
  
// Text in link/button (hover)
.custom-select--link .select2-choice:hover .select2-chosen, .custom-select--link .custom-select__btn:hover .custom-select__text {
  color: $link-hover-color;
}

// Text in link/button (active)
.custom-select--link .select2-container-active .select2-choice, .custom-select--link .custom-select__btn .custom-select__text.focus, .custom-select--link .custom-select__btn .custom-select__text:focus {
  background-color: rgba($white, 1);
}

.custom-select__btn .custom-select__arrow {
  @include dropdown-arrow($has-child: false);

  position: absolute;
  right: 0;
  height: $p21-select-choice-arrow-size * 2;
}

// Select menu
.custom-select-menu {
  min-width: 150px;
}

.custom-select__text--lg {
  min-width: $font-size-base * 14;
}


/* =============================================================================
// Date Range Picker Base
  ========================================================================== */
.daterangepicker {
  font-family: inherit;
  color: $body-color;
  line-height: $line-height-base;
  margin-top: $dropdown-border-width;
  border: $dropdown-border-width solid $dropdown-border-color;
  border-radius: $dropdown-border-radius;
  box-shadow: $dropdown-box-shadow;

  &::before, &::after {
    display: none;
  }
}

// List of options
.daterangepicker .ranges {
  padding: 0;
  margin: 4px 0;

  & li {
    border: $input-btn-border-width solid transparent;
    font-size: $font-size-base;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  }

  & li:hover {
    background: $dropdown-link-hover-bg;
  }

  & li.active {
    background: $dropdown-link-active-bg;
    border-color: $dropdown-link-active-border;
    color: $dropdown-link-active-color;
    position: relative;

    // @include selected-state;
  }
}

// Calendar picker
.daterangepicker .calendar-table th {
  padding: 0;
  line-height: 2;
  cursor: default;

  &.available:hover {
    background: $dropdown-link-hover-bg;
    color: $body-color;
  }

  &.next, &.prev {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: $badge-transition;
    cursor: pointer;
  }

  &.next span {
    margin-left: -3px;
  }

  &.prev span {
    margin-right: -3px;
  }

  &.next:hover, &.prev:hover {
    background: rgba($custom-select-indicator-color, 0.15);
  }
}

.daterangepicker .calendar-table td {
  padding: 0.25rem;
  line-height: 2;

  &.available:hover {
    background: $dropdown-link-hover-bg;
    color: $body-color;
  }

  &.in-range {
    background: $primary;
    color: $white;
  }

  &.active, &.active:hover, &.end-date, &.end-date:hover {
    background: $primary-900;
    color: $white
  }
}

.daterangepicker .drp-buttons .btn {
  font-weight: $font-weight-normal;
}


/* =============================================================================
// Filters Menu Container Base
//
// BS3 Select2 dropdown (e.g., Analytics)
  ========================================================================== */
  .filters-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }


/* =============================================================================
   React Select Base
   ========================================================================== */
.select-control-container, .react-select {  

  .react-select__control {
    min-height: $input-height-sm;
    border-radius: $input-border-radius-sm;
    border: $input-border-width solid $input-border-color;
    box-shadow: $input-box-shadow;

    &--is-focused {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }

    &:hover {
      border-color: $custom-select-indicator-color;
      cursor: pointer;
    }
  }

  .react-select__placeholder {
    color: $input-placeholder-color;
  }

  .react-select__single-value {
    color: $input-color;
  }

  // Value selected in the dropdown
  .react-select__value-container {
    line-height: 1.4;
  }

  // Divider between dropdown icon and clear selection
  .react-select__indicator-separator {
    width: 0;
    background: $input-border-color;
  }

  .react-select__indicator+.react-select__indicator-separator {
    width: $input-border-width;
  }

  // Dropdown icons
  .react-select__indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $input-padding-y-sm;
    color: $custom-select-indicator-color;
    border-radius: 50%;
    transition: $badge-transition;
  }

  .react-select__indicator:hover {
    color: $custom-select-indicator-color;
    background: rgba($custom-select-indicator-color, 0.15);
  }

  // Clear selection icon
  .react-select__clear-indicator:hover  {
    color: $danger;
    background-color: rgba($danger, 0.15);
  }

  // Dropdown menu selections
  .react-select__menu {
    z-index: $zindex-dropdown;
    margin-top: $input-border-width;
    background: $dropdown-bg;
    border: $dropdown-border-width solid $dropdown-border-color;
    border-radius: $dropdown-border-radius;
    box-shadow: $dropdown-box-shadow;
  }

  // Dropdown menu item/option
  .react-select__option {
    display: flex;
    align-items: center;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;

    &:hover {
      background-color: $dropdown-link-hover-bg;
      color: $dropdown-link-active-color;
    }

    &:hover, &:hover * {
      cursor: pointer;
    }

    &--is-focused {
      background-color: $white;
      color: $dropdown-link-hover-color;
      text-decoration: none;
    }

    &--is-selected, 
    &:active {
      background: $white;
      color: $dropdown-link-active-color;
    }

    &--is-disabled,
    &:disabled {
      color: $dropdown-link-disabled-color;
      background-color: transparent;
      opacity: $btn-disabled-opacity;
      pointer-events: none;
      cursor: default;
      // Remove CSS gradients if they're enabled
      @if $enable-gradients {
        background-image: none;
      }
    }
  }
}

// only add selected borders to react selects when they are Issues list filters
.react-select__option:not(.extra-filter *) {
  border: $input-btn-border-width solid transparent;
}

.react-select__option--is-selected:not(.extra-filter *), 
.react-select__option:active:not(.extra-filter *) {
  background: $dropdown-link-active-bg;
  border-color: $dropdown-link-active-border;
  color: $dropdown-link-active-color;
}


/* =============================================================================
    React Select Filters Base
    ========================================================================== */
.dropdown-blanket {
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: $zindex-dropdown - 1;
}

.dropdown-menu-im {
  position: absolute;
  z-index: $zindex-dropdown;
  min-width: $dropdown-min-width;
}


/* =============================================================================
     Select Overrides
    ========================================================================== */
.select2-container {
  .compareModal &,
  .copyModal & {
    width: 80%;
  }
}

// Make the select container input height smaller. Used in Add Object Wizard dataset destination dropdown.
.select2-container--sm {
  & .select2-choice {
    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
  }

  &.form-group {
    & .d-flex {
      align-items: center;
    }
  }

  & .col-form-label {
    padding: 0;
  }
}
/* =============================================================================
   Utilities
   ========================================================================== */

.pointer-events-auto {
  pointer-events: auto !important;
}

// Max and min width from 50px to 800px in 50px increments
$start-width: 50;
@while $start-width < 800 {
  .max-width-#{$start-width} {
    max-width: #{$start-width}px;
  }

  .min-width-#{$start-width} {
    min-width: #{$start-width}px;
  }
  $start-width: $start-width + 50;
}


/* =============================================================================
   Module spacing https://pineco.de/get-to-know-the-lobotomized-owl-selector/
   ========================================================================== */

// clear out default bottom margin for all direct descendants
.space-y > * {
	margin-bottom: 0;
}

// add spacing to the top of direct descendants according to spacing map
@each $size, $length in $spacers {
  .space-y-#{$size} > * + * {
    margin-top: $length;
  }
}


/* =============================================================================
   Overflow
   ========================================================================== */
.overflow-y-sm {
  max-height: 11rem;
}

.overflow-y-md {
  max-height: 22rem;
}

.overflow-y-auto	{
  overflow-y: auto;
}


/* =============================================================================
   Border
   ========================================================================== */
// Border mixin for each position of top, right, bottom, and left
@mixin double-border($position) {
  position: relative;
  margin-#{$position}: $spacer;
  padding-#{$position}: $spacer;

  border-#{$position}-width: $border-width;
  border-#{$position}-style: solid;

  @if $position == bottom or $position == left {
    border-#{$position}-color: $white;
  } @else {
    border-#{$position}-color: $border-color;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    #{$position}: 0;
    @if $position == top or $position == bottom {
      width: 100%;
      left: 0;
    } @else {
      height: 100%;
      top: 0;
    }

    border-#{$position}-width: $border-width;
    border-#{$position}-style: solid;
    @if $position == bottom or $position == left {
      border-#{$position}-color: $border-color;
    } @else {
      border-#{$position}-color: $white;
    }
  }
}

.double-border-top {
  @include double-border(top);
}

.double-border-bottom {
  @include double-border(bottom);
}

.double-border-right {
  @include double-border(right);
}

.double-border-left {
  @include double-border(left);
}

/* =============================================================================
   Card Base
   ========================================================================== */
.card-title {
    margin-bottom: $card-spacer-y / 2;
}
  
.card-subtitle {
    margin-bottom: $card-spacer-y / 2;
    color: $text-muted;
}

/* =============================================================================
   Row of Cards
   ========================================================================== */
.card-panel {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

.card-panel--left-align {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: $spacer;
}

.card-panel-footer {
    flex: 1 0 100%;
}

// Secondary option usually just a link
.ghost-option {
    max-width: 470px;
    margin: $spacer auto;
    padding: $spacer / 2;
    border-top: $border-width solid $border-color;
}

/* =============================================================================
   Card Disabled state
   ========================================================================== */
.card.is-disabled--with-cta {
    & .show-when-enabled {
        display: none;
    }
}

.card.is-enabled {
    & .show-when-disabled {
        display: none;
    }
}

/* =============================================================================
   Card Header
   ========================================================================== */
.card-header {
    padding-bottom: 0;
    border-bottom: none;

    // Callout design for cards to draw attention
    & .badge {
        position: absolute;
        top: $card-border-width * -1;
        right: $card-border-width * -1;
        padding: 0.5rem 0.75rem;
        font-size: $font-size-base;
        border-radius: 0 $card-border-radius 0 $card-border-radius;
    }

    & .badge-p21 {
        color: $white;
        background: $orange;
    }

    // Make gray when the card is disabledz
    .is-disabled--with-cta & .badge {
        background: $gray-600;
    }

    .card-header__icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    
        & .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: 25px; // Half the height of the hexagon. Didn't use rem b/c img background size is in px.
            color: $primary;
            transform: translate(-50%, -50%);
        }
    
        .is-disabled--with-cta & .icon {
            color: $gray-600;
        }
    
        & .icon-background {
            opacity: 0.2;
        }
    
        .is-disabled--with-cta & .icon-background {
            opacity: 0.12;
        }
    }
}

/* =============================================================================
   Card Body
   ========================================================================== */
.card-title {
    margin-top: 0;
    font-size: $h3-font-size;
    font-weight: $font-weight-normal;
    color: $gray-700;

    & .link, .link:visited, a, a:visited {
        color: $gray-700;
    }

    & .link:hover, a:hover {
        color: $gray-900;
        text-decoration: underline;
        text-decoration-thickness: 1px;
    }
}

.img-crown-top-user {
    position: absolute;
    z-index: 2;
    top: $card-spacer-y;
    left: 50%;
    transform: translateX(-50%);
}

.top-user__avatar {
    position: relative;
    margin-bottom: $spacer * .25;
}

.top-user__avatar-img {
    &.is-inactive {
        opacity: 0.5;
    }
}

.top-user__inactive-label {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

/* =============================================================================
   Card Footer
   ========================================================================== */
.card-footer {
    padding-top: 0;
    border-width: 0;

    & .btn {
        margin: 0 ($spacer / 2);
    }

    &--stats {
        display: flex;
        justify-content: center;
        margin: 0 $card-spacer-x;
        padding: $card-spacer-y 0;
        border-width: $card-border-width;
    }
}

/* =============================================================================
   Card Utilities / Sizes
   ========================================================================== */
.card--md {
    flex: 0 1 370px;
    max-width: 370px;
    margin: $spacer / 2;
}

.card--top-users {
    flex: 0 1 33%;
    margin-right: $spacer;

    &:last-of-type {
        margin-right: 0;
    }
}

.card-xs .card-body {
    padding: calc(#{$spacer} / 2) calc(#{$spacer} / 1.5);
}

/* =============================================================================
   Card Groups
   ========================================================================== */
.card-collection {
    display: flex;
    
    &--horizontal {
        .card + .card {
            margin-left: .65rem;
        }
    }
}
/* =============================================================================
    Local Placeholders
   ========================================================================== */
// Clip cell value (.ht-cell__value) with space for one icon on the right
%clip-cell-content {
  position: relative;
  overflow: visible;

  & .ht-cell__value {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - #{$p21-ht-cell-dropdown-icon-size} - #{$p21-ht-cell-padding-x});
    margin-bottom: $p21-ht-cell-padding-x * -1;
  }
}


/* =============================================================================
    Handsontable Base: Headers 
   ========================================================================== */
.handsontable thead th {
  background: url('/assets/images/table-hdr.jpg') repeat-x $gray-600;
  color: $white;
  font-weight: $font-weight-bold;
  text-shadow: $gray-800 1px 1px 1px;
  text-align: left;
  letter-spacing: 0.7px;
}

.handsontable.ht__selection--columns thead th.ht__highlight {
  color: $white;
}

// Handsontable sort indicators 
.handsontable .columnSorting {
  &::after {
    content: '';
    display: inline-block;
    margin-left: 0px;
    margin-bottom: -3px;
    width: 16px;
    height: 16px;
    background-image: url('/assets/images/ui-icons_cccccc_256x240.png');
    background-position: -128px 0;
  }

  &.ascending::after {
    content: '';
    position: inherit;
    right: 0px;
    background-position: -64px -16px;
  }

  &.descending::after {
    content: '';
    position: inherit;
    right: 0px;
    background-position: 0 -16px;
  }
}

.ht_clone_top .wtHolder {
  overflow-x: hidden;
}

/* =============================================================================
    Handsontable Base: Cells 
   ========================================================================== */
.handsontable td {
  // Cell warning
  &.invalid-warning {
    background-color: $p21-cell-bg-warning;
  }

  &.invalid-warning i {
    color: $warning;
    font-size: 1rem;
  }

  // Cell error
  &.invalid-error {
    background-color: $p21-cell-bg-danger;
  }
  &.invalid-error i {
    color: $danger;
    font-size: 1rem;
  }

  // Cell read only
  &.read-only,
  &.htDimmed:not(.listbox) {
    color: $body-color;
    background: $p21-ht-cell-disabled-bg;
    cursor: default;

    & .ht-cell__icon-dropdown {
      display: none;
    }
  }
}

// Handsontable containers
.handsontable div {
  // Handsontable tooltips
  &.tooltip {
    position: fixed;
  }

  // Handsontable cell editing dropdowns
  &.autocompleteEditor {
    position: fixed;
  }
}


/* =============================================================================
    Handsontable Base: Dropdown Menu 
   ========================================================================== */
// Handsontable filter dropdown
.handsontable .changeType {
  background: $gray-600;
  color: $gray-400;

  &:hover {
    border: $border-width solid $border-color;
    color: $gray-300;
    background: $gray-500;
  }
}

// Change color of dropdown arrow when filter is applied
.handsontable .htFiltersActive .changeType {
  border-color: $success;
  color: darken($success, 7%);
  background-color: lighten($success, 45%);
}

//Dropdown Menu
.htDropdownMenu {

  // Handsontable dropdown container menu
  &.handsontable .ht_master {
    & .htCore {
      border: none;
    }
    
    & .htCustomMenuRenderer {
      border: $dropdown-border-width solid $dropdown-border-color;
      border-radius: $dropdown-border-radius;
      background-color: $dropdown-bg;
      box-shadow: $dropdown-box-shadow;
    }

    & .htFiltersMenuValue {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & .htFiltersMenuActionBar {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  // Search bar
  & .htFiltersMenuValue .htUIMultipleSelectSearch input {
    padding: 0.125rem 0.25rem;
  }

  // Select option container
  .htUIMultipleSelectHot .handsontable .htCore td {
    padding: 0.25rem 0;

    &:hover {
      background-color: $p21-table-row-bg-hover;
    }
  }

  // Select option label
  & label {
    font-weight: $font-weight-normal;
    margin-bottom: 0;
  }

  // Select and clear links container
  & .htUISelectionControls {
    margin-bottom: $label-margin-bottom;

    // Links
    & a {
      @extend .link;
    }
  }

}
// Dropdown menu buttons
.handsontable .htUIInput.htUIButton {

  input {
    @extend .btn;
    @extend .btn-sm;
    font-family: $font-family-base;
    height: auto;
  }

  &OK input {
    @extend .btn-primary;
  }

  &Cancel input {
    @extend .btn-light;
  }
}


/* =============================================================================
    Handsontable Base: Cell Borders
   ========================================================================== */
.current.wtBorder {
    background-color: $primary !important;
}
.handsontableInput {
    box-shadow: inset 0 0 0 2px $primary;
}


/* =============================================================================
    Handsontable Overrides: Cell Status Icons
   ========================================================================== */
.handsontable .is-invalid {
  position: relative;

  & .status-icon {
    position: absolute;
    top: $p21-ht-cell-padding-x;
    right: $p21-ht-cell-padding-x;
    font-size: $font-size-base;
  }

  // Hide toolbar when hovering over the status icon
  & .status-icon:hover~.ht-cell__toolbar {
    display: none;
  }
}

.handsontable .ht-cell--with-dropdown .status-icon {
  right: calc(#{$p21-ht-cell-dropdown-icon-size} + #{$p21-ht-cell-padding-x} + 0.25rem);
}


/* =============================================================================
    Handsontable Overrides: Cells with Dropdowns
   ========================================================================== */
.ht-cell.ht-cell--with-dropdown {
  @extend %clip-cell-content;

  // Value width to make room for 2 icons (dropdown AND status)
  &.is-invalid .ht-cell__value {
    width: calc(100% - ((#{$p21-ht-cell-dropdown-icon-size} * 2) + #{$p21-ht-cell-padding-x} + 0.25rem));
  }

  // Dropdown arrow icon
  & .ht-cell__icon-dropdown {
    position: absolute;
    right: 0;
    top: $p21-ht-cell-padding-x;
    width: $p21-ht-cell-dropdown-icon-size;
    padding: $p21-ht-cell-padding-y $p21-ht-cell-padding-x;
    color: $gray-200;
    font-size: $p21-ht-cell-dropdown-icon-size;
    cursor: default;
  }

  &.is-invalid .ht-cell__icon-dropdown {
    color: $gray-400;
  }

  & .ht-cell__icon-dropdown:hover {
    color: $gray-500;
  }
}

// Dropdown menu
.handsontable .autocompleteEditor {
  &.handsontable {
    margin-top: ($p21-ht-cell-padding-x + $border-width) * -1;
  }

  & .wtHider {
    box-shadow: $p21-ht-cell-box-shadow;
  }
}

/* =============================================================================
    Handsontable Overrides: Cells with Toolbar (that open modal editors
   ========================================================================== */
.ht-cell.ht-cell--with-toolbar {
  @extend %clip-cell-content;

  &:hover {
    box-shadow: inset 0 0 0 2px $dark;
  }

  &.current.highlight:hover {
    box-shadow: none;
  }

  & .ht-cell__toolbar {
    display: none;
    flex-wrap: nowrap;
    position: absolute;
    margin: 0;
    top: calc(((#{$btn-padding-y-sm} * 2) + #{$btn-font-size-sm} + (#{$p21-ht-cell-padding-x} * 2) + 1px) * -1);
    right: $p21-ht-cell-padding-x;
    z-index: $zindex-dropdown;
    &.is-visible {
      display: inline-flex;
    }
  }

  & .ht-cell__toolbar-btn {
    padding: 0.25rem 0.5rem;
    font-size: $font-size-base * .875;
    box-shadow: $p21-ht-cell-box-shadow;
    white-space: nowrap;

    @include hover() {
      color: $white;
      background: lighten($dark, 10%);
      text-decoration: none;
    }
  
    &:focus,
    &.focus {
      outline: 0;
      box-shadow: $btn-focus-box-shadow;
    }
  
    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      opacity: $btn-disabled-opacity;
      @include box-shadow(none);
    }
  
    &:not(:disabled):not(.disabled) {
      cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  
      &:active,
      &.active {
        @include box-shadow($btn-active-box-shadow);
  
        &:focus {
          @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
      }
    }
  }
}


/* =============================================================================
    Handsontable Overrides: Chat Threads
   ========================================================================== */
[data-has-chat="active"] {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid $warning;
  }
}

[data-has-chat="resolved"] {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid $success;
  }
}

// ==========================================================================
//  Handsontable Overrides: Define: Hides the padding in the cloned header
//  that blocks the mouse events in the first row
// ==========================================================================
.ht_clone_top .wtHolder {
  overflow-x: hidden;
}

@import 'variable-overrides';

/* =============================================================================
   Other Library Defaults
   ========================================================================== */
// Deprecated Bootstrap Libraries
//@import '../library-defaults/bootstrap-dialog.css';
//@import '../library-defaults/bootstrap-docs.min.css';
@import '../library-defaults/jquery.gritter.css';

// Form Elements
@import '../library-defaults/datepicker.css';
@import '../library-defaults/daterangepicker@3.1.0.css';
@import '../library-defaults/select2.css';

// DataTable Libraries
@import '../library-defaults/dataTables.jqueryui.css';
@import '../library-defaults/buttons.jqueryui.min.css';
@import '../library-defaults/rowGroup.jqueryui.min.css';
//@import '../library-defaults/jquery.dataTables.css';

// HandsonTable Libraries
@import '../library-defaults/handsontable.css';

// Highcharts Libaries
//@import '../library-defaults/highcharts.css';

// Misc Libaries
//@import '../library-defaults/react-draft-wysiwyg.css';
//@import '../library-defaults/scroller.jqueryui.min.css';
@import '../library-defaults/summernote.css';

/* =============================================================================
   Bootstrap Defaults
   ========================================================================== */
@import 'bootstrap@4.5.2/functions';
@import 'bootstrap@4.5.2/variables';
@import 'bootstrap@4.5.2/mixins';

@import 'bootstrap@4.5.2/reboot';
@import 'bootstrap@4.5.2/type';
@import 'bootstrap@4.5.2/code';
@import 'bootstrap@4.5.2/grid';
@import 'bootstrap@4.5.2/tables';
@import 'bootstrap@4.5.2/forms';
@import 'bootstrap@4.5.2/buttons';
@import 'bootstrap@4.5.2/transitions';
@import 'bootstrap@4.5.2/dropdown';
@import 'bootstrap@4.5.2/button-group';
@import 'bootstrap@4.5.2/input-group';
@import 'bootstrap@4.5.2/custom-forms';
@import 'bootstrap@4.5.2/nav';
@import 'bootstrap@4.5.2/card';
@import 'bootstrap@4.5.2/breadcrumb';
@import 'bootstrap@4.5.2/badge';
@import 'bootstrap@4.5.2/alert';
@import 'bootstrap@4.5.2/progress';
@import 'bootstrap@4.5.2/close';
@import 'bootstrap@4.5.2/modal';
@import 'bootstrap@4.5.2/tooltip';
@import 'bootstrap@4.5.2/popover';
//@import 'bootstrap@4.5.2/carousel';
@import 'bootstrap@4.5.2/utilities';
@import "bootstrap@4.5.2/print";


/* =============================================================================
   P21E Bootstrap UI Custom Styling
   ========================================================================== */
@import 'mixins-P21';
@import 'base';
@import 'type-P21';
@import 'forms-P21';
@import 'buttons-P21';
@import 'transitions-P21';
@import 'dropdown-P21';
@import 'forms-custom-inputs-P21';
@import 'tabs-P21';
@import 'card-P21';
@import 'breadcrumb-P21';
@import 'badge-P21';
@import 'alerts-P21';
@import 'progress-P21';
@import 'list-group-P21';
@import 'modal-P21';
@import 'tooltip-P21';
@import 'popover-P21';


/* =============================================================================
   P21E Non-Bootstrap UI Custom Styling
   ========================================================================== */
@import 'animations-P21';
@import 'loaders-P21';
@import 'select-P21';
@import 'sidebar';
@import 'widget-box-P21';

@import 'empty-states-P21';
@import 'error-states-P21';
@import 'pages-P21';

// P21 styling
@import 'handsontables-P21';
@import 'highcharts-P21';
@import 'datatables-P21';
@import 'miller-columns';

//@import 'wysiwyg';
//@import 'split';
@import 'comment';
//@import 'activity';

// Utilities
@import 'utilities-P21';

/* =============================================================================
   Alerts Base
   ========================================================================== */
.bootstrap-growl  {
    // Add color to each shadow based on the color of the alert
    @each $color, $value in $theme-colors {
        &.alert-#{$color} {
                box-shadow: 0 10px 20px rgba(darken($value, 23%), 0.19), 0 6px 6px rgba(darken($value, 15%), 0.23);
            }
    }
    animation: slideLeft 0.8s cubic-bezier(.4, .0, .23, 1);
        
    & a {
        margin-right: $spacer;
    }
}

.alert--attention {
    animation: shakeX 1.5s ease-in-out 0.8s 3;
}

@keyframes slideLeft {
    from {
        opacity: 0;
        transform: translateX(65px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
  }

  @keyframes shakeX {
    0% {
      transform: translateX(0);
    }
  
    6.5% {
      transform: translateX(-10px);
    }
  
    18.5% {
      transform: translateX(7px);
    }
  
    31.5% {
      transform: translateX(-5px);
    }
  
    43.5% {
      transform: translateX(2px);
    }
  
    50% {
      transform: translateX(0);
    }
  }


/* =============================================================================
   Toast (React) Base: Match Alert styling
   ========================================================================== */
  .react-toast-notifications__toast {
    @each $color, $value in $theme-colors {
        &.react-toast-notifications__toast--#{$color} {
          @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
          border-width: $alert-border-width;
          border-style: solid;
          border-radius: $alert-border-radius;
          box-shadow: 0 6px 6px rgba(darken($value, 23%), 0.19), 0 3px 3px rgba(darken($value, 15%), 0.3);
        }

        &.react-toast-notifications__toast--#{$color} .react-toast-notifications__toast__icon {
            fill: theme-color-level($color, $alert-color-level) !important;
        }

        &.react-toast-notifications__toast--#{$color} .react-toast-notifications__toast__countdown {
            background-color: rgba(theme-color-level($color, $alert-border-level), 0.4);
        }
    }

    & .react-toast-notifications__toast__icon-wrapper {
        background-color: transparent;
    }
  }
  

/* =============================================================================
   Alerts Overrides
  ========================================================================== */
.alert--thin {
  padding: ($alert-padding-y / 2) $alert-padding-x;
}

.alert--thread-state {
  width: 100%;
  text-align: center;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

#gritter-notice-wrapper {
    z-index: $zindex-modal;

    ~ .select2-drop {
        z-index: $p21-zindex-select;
    }
}

.gritter-item-wrapper {
    & .gritter-item {
        padding: $modal-inner-padding;
        font-size: $font-size-base;
        background: $white;
        border: $alert-border-width solid $border-color;
        border-radius: $alert-border-radius;
        box-shadow: $box-shadow;
    }

    & .gritter-without-image {
      text-align: left;
    }
}

/* =============================================================================
    Miller Columns (Add Object Wizard)
   ========================================================================== */
   .miller-columns {
    display: flex;
    align-items: flex-start;
    min-height: 100%;

    // give a pointer to cursor to let users know table rows are selectable
    tr:hover {
      cursor: pointer;
    }
  }
  
  .miller-columns__select-group {
    flex: 1 1 70%;
    overflow-x: auto;
    overflow-y: visible;
    height: 100%;
  }
  
  .miller-columns__column {
    flex: 1 1 auto;
    width: 33%;
    min-width: 350px;
    height: calc(100% - 5px);
    margin: 0;
    background-color: $p21-widget-background-color;
    border: $p21-widget-border-size $p21-widget-border-style $p21-widget-border-color;
    border-radius: 0;
    box-shadow: $p21-widget-box-shadow;
  }
  
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    .miller-columns__column {
      height: 96%;
    }
  }
  
  /* Final column/panel to review selected items (aka cart) */
  .miller-columns__column--review {
    max-width: 400px;
    margin-left: 1.5rem;
    overflow-x: auto;
    overflow-y: hidden;
  }

  
/* =============================================================================
  Miller Columns Header
   ========================================================================== */
  .miller-columns__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: $form-grid-gutter-width;
    background-image: $p21-widget-header-gradient;
    border-radius: 0;
    border-bottom: $p21-widget-border-size $p21-widget-border-style $p21-widget-border-color;
    box-shadow: $p21-widget-header-box-shadow;

    & .select2-choice {
      height: auto;
      padding: $input-padding-y / 2 $input-padding-x;
    }
  }

  .miller-columns__title {
    margin: 0;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: $line-height-base;
    color: $gray-600;
    text-shadow: 0 1px 0 $white;
  }
  
  /* Search container */
  .miller-columns__header .dataTables_filter {
    float: none;
    margin: 0 1rem;
    flex: 1 1 auto;
  }
  
  /* Searchbar input */
  .miller-columns__header input[type='search'] {
    width: 100%;
  }
  
  /* Dropdown opens flush right to the button */
  .miller-columns__header .dropdown-menu {
    left: auto;
    right: 0;
  }


/* =============================================================================
    Toolbar
   ========================================================================== */
.miller-columns__toolbar {
  padding: $form-grid-gutter-width;
  border-bottom: $p21-widget-border-size $p21-widget-border-style $p21-widget-border-color;
  background: $gray-300;
  box-shadow: inset 0 7px 7px -7px rgba($black, 0.20),
              inset 0 2px 2px -2px rgba($black, 0.16),
              inset 0 -4px 2px -4px rgba($black, 0.16),
              inset 0 -2px 1px -2px rgba($black, 0.20);

  & .col-form-label {
    color: $gray-900;
  }
}  


/* =============================================================================
    Sub Headers / DataTable Headers
   ========================================================================== */
  .miller-columns .miller-columns__column .dataTables_scroll {
    background: none;
  }
  
  .miller-columns .miller-columns__column .dataTables_scroll .dataTables_scrollHead {
    background: $gray-100;
    border-bottom: $p21-widget-border-size $p21-widget-border-style $p21-widget-border-color !important;
  }
  
  .miller-columns .miller-columns__column .dataTable thead th {
    color: $gray-900;
    font-weight: bold;
    text-shadow: none;
    background: none;
    border: none;
  }
  

  /* =============================================================================
    DataTable Last Column
     ========================================================================== */
  .miller-columns .miller-columns__column .actions-column {
    text-align: center;
  }
  
  .miller-columns .miller-columns__column .actions-column .link {
    font-weight: normal;
  }
  
  .miller-columns .miller-columns__column .actions-column .btn {
    width: 100%;
  }
  
  /* Action column icon */
  .miller-columns__column .dataTable tr .icon-select-next {
    width: 100%;
    font-size: 1.5rem;
    opacity: 0.5;
    cursor: pointer;
  }
  
  .miller-columns__column .dataTable tr:hover .icon-select-next, .miller-columns__column .dataTable .selected .icon-select-next {
    opacity: 1;
  }
 
/* =============================================================================
    Summary Review / DataTable Footer
    ========================================================================== */
  .miller-columns__footer {
    position: relative;
    z-index: 3;
    display: block;
    background: $white;
    padding: $p21-widget-spacer-y $p21-widget-spacer-x;
    font-size: $font-size-base;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25); 
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25); 
    opacity: 1;
    transform: translateY(0);
    transform-origin: bottom center;
    transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
  }
  
  .miller-columns__footer--empty {
    opacity: 0;
    transform: translateY(150%);
  }
  
  .order-review__title {
    max-width: 11rem;
    margin-left: auto;
    margin-bottom: 0.5rem;
    color: $text-muted;
    border-bottom: $p21-widget-border-size $p21-widget-border-style $p21-widget-border-color;
  }
  
  .order-review__line-item {
    display: flex;
    justify-content: space-between;
    max-width: 11rem;
    margin-left: auto;
    margin-bottom: 0.5rem;
  }
  
  .order-review__line-item:last-of-type {
    margin-bottom: 0;
  }


  /* =============================================================================
  Add Object CTAs
  ========================================================================== */
  .add-object__btn-primary {
    min-width: 7.5rem;
  }

  /* =============================================================================
    Guiding Animation
  ========================================================================== */
  .is-pulsing-border {
    animation: border-pulse 2s cubic-bezier(0, 0, 0.2, 1) infinite;
    border-color: $primary !important;
  }

  .is-pulsing-border-5x {
    animation: border-pulse 2s cubic-bezier(0, 0, 0.2, 1) 5;
    border-color: $primary !important;
    position: relative;
    z-index: 1;
  }

  .is-pulsing-border-danger {
    animation: border-pulse-danger 2s cubic-bezier(0, 0, 0.2, 1) infinite;
    border-color: $danger !important;
  }

  .is-pulsing-border-5x-danger {
    animation: border-pulse-danger 2s cubic-bezier(0, 0, 0.2, 1) 5;
    border-color: $danger !important;
    position: relative;
    z-index: 1;
  }

  @keyframes border-pulse {
    0% {
      box-shadow: 0 0 0 -1px rgba($primary, 0.75);
    }
    75% {
      box-shadow: 0 0 0 16px rgba($primary, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba($primary, 0);
    }
  }

  @keyframes border-pulse-danger {
    0% {
      box-shadow: 0 0 0 -1px rgba($danger, 0.75);
    }
    75% {
      box-shadow: 0 0 0 16px rgba($danger, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba($danger, 0);
    }
  }